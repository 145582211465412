<template>
  <Route class="HeroScrollDown" :to="{ name: $route.name, hash }" @click.native="scrollToHash">
    <span class="scroll-label">
      {{ $t('scroll') }}
    </span> <Icon name="scroll-down" />
  </Route>
</template>

<script>
import { scrollToHash } from '../../router/smoothScroll'

export default {
  props: {
    hash: String,
  },
  methods: {
    scrollToHash() {
      scrollToHash(this.hash)
    },
  },
}
</script>

<style lang="scss">
.HeroScrollDown {
  background: $main;
  bottom: 0;
  color: $white;
  cursor: pointer;
  display: block;
  font-weight: $semibold;
  min-width: 42vw;
  padding: 3rem;
  position: absolute;
  right: 0;

  > .Icon {
    font-size: 0.5rem;
    margin-left: 0.75rem;
  }

  &:hover,
  &:focus {
    color: $white;

    > .scroll-label {
      text-decoration: underline;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "scroll": "Scrollen Sie für mehr"
  },
  "en": {
    "scroll": "Scroll for more"
  },
  "it": {
    "scroll": "Scorrere"
  },
  "lt": {
    "scroll": "Žemyn"
  },
  "pl": {
    "scroll": "Przewiń, by dowiedzieć się więcej"
  }
}
</i18n>

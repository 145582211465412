<template>
  <div class="BtnShare" @click="isOpen = !isOpen" @keypress.enter="isOpen = !isOpen">
    <BtnRound>
      <Icon :name="isOpen ? 'cross' : 'share'" />
    </BtnRound>
    <div class="share-options">
      <transition
        v-for="(option, index) in shareOptions"
        :key="option.icon"
        appear
        :name="`BtnShare-fade-${index}`">
        <a
          v-if="isOpen"
          class="Btn BtnRound"
          :class="option.icon"
          :href="option.url"
          target="_blank"
          v-on="option.listeners || {}">
          <Icon :name="option.icon" />
        </a>
      </transition>
    </div>
  </div>
</template>

<script>
import BtnRound from 'views/components/BtnRound'
import { actions } from '../utils'
import { webUrl } from '../../config'

export default {
  components: { BtnRound },
  props: {
    to: [Object, String],
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    directLink() {
      if (typeof this.to !== 'string' || !this.to.startsWith('http')) return

      return this.to
    },
    route() {
      if (this.directLink) return
      if (!this.to) return this.$route

      return this
        .$router
        .resolve(typeof this.to === 'string'
          ? { name: this.to }
          : this.to)
        .resolved
    },
    shareOptions() {
      return [
        {
          icon: 'facebook',
          url: `https://www.facebook.com/sharer/sharer.php?u=${this.url}`,
        },
        {
          icon: 'linkedin',
          url: `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}&title=&summary=&source=`,
        },
        {
          icon: 'link',
          listeners: {
            click: (event) => {
              event.preventDefault()
              this.$copyText(this.url)
              this.SUCCESS(this.$t('copied'))
            },
          },
        },
      ]
    },
    url() {
      if (this.directLink) return this.directLink

      const baseUrl = webUrl.endsWith('/') ? webUrl.slice(0, -1) : webUrl

      return `${baseUrl}${this.route.fullPath}`
    },
  },
  methods: {
    closeOnAwayClick(event) {
      if (!this.isOpen) return

      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    },
    ...actions(),
  },
  async beforeMount() {
    this.$offEvents = await this.ON({
      click: this.closeOnAwayClick,
    })
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
$_facebook-color: #3b5998;
$_linkedin-color: #0077b5;
$_link-color: $grey;
$_size: 3.5rem;
$_size-sm: 2.5rem;

.BtnShare {
  display: inline-block;
  position: relative;

  .BtnRound {
    box-shadow: $shadow-md;
    font-size: $h4;
    height: $_size;
    line-height: $_size;
    width: $_size;
  }

  > .BtnRound {
    z-index: 2;
  }

  .share-options {
    bottom: $_size;
    position: absolute;
    right: 0;
    width: $_size;
    z-index: 1;

    .BtnRound {
      @include trans(0.4s);
      color: $white;
      margin-bottom: 1rem;

      &.facebook {
        background: $_facebook-color;

        &:hover,
        &:focus {
          color: $_facebook-color;
        }
      }

      &.linkedin {
        background: $_linkedin-color;

        &:hover,
        &:focus {
          color: $_linkedin-color;
        }
      }

      &.link {
        background: $_link-color;

        &:hover,
        &:focus {
          color: $_link-color;
        }
      }

      &:hover,
      &:focus {
        background: $white;
      }
    }
  }

  &.sm {
    .BtnRound {
      height: $_size-sm;
      line-height: $_size-sm;
      width: $_size-sm;
    }

    .share-options {
      bottom: $_size-sm;
      position: absolute;
      right: 0;
      width: $_size-sm;

      .BtnRound {
        margin-bottom: 0.5rem;
      }
    }
  }
}

// Slide Fade
.BtnShare-fade {
  &-0,
  &-1,
  &-2 {
    &-enter,
    &-leave {
      &-active {
        @include trans(0.5s);
      }
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }

  &-0 {
    &-enter,
    &-leave-to {
      transform: translateY(12rem);
    }
  }

  &-1 {
    &-enter,
    &-leave-to {
      transform: translateY(6rem);
    }
  }

  &-2 {
    &-enter,
    &-leave-to {
      transform: translateY(2rem);
    }
  }
}
</style>

<i18n>
{
  "de": {
    "copied": "Link kopiert!"
  },
  "en": {
    "copied": "Link copied!"
  },
  "it": {
    "copied": ""
  },
  "lt": {
    "copied": "Nuoroda nukopijuota!"
  },
  "pl": {
    "copied": "Skopiowany!"
  }
}
</i18n>

import authMap from './authMap'
import router from '../router'
import store from '@/store'

const checkRoute = (from, to) =>
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  doChecks(from, to).catch((error) => {
    router.replace({ name: (error?.code) ? `Err${error.code}` : 'Login' })
  })

const doChecks = async (from, to) => {
  const auth = authMap[to.name]
  if (!auth) return Promise.resolve()

  await store.dispatch('CHECK_LOGIN')

  return doAllAuth(auth, from, to)
}

const passAuth = (from, to) => conditions => Promise
  .all(conditions.map(condition => condition({ from, router, store, to })))

async function doAllAuth(auth, from, to) {
  // always enforce logged in auth
  if (!store.getters.isAuth) return Promise.reject(new Error('Nope!'))

  const authResult = await passAuth(from, to)(auth)

  if (!authResult.some(authPass => authPass)) {
    throw new Error('Unauthorized')
  }
}

export { checkRoute, doChecks }

<template>
  <div class="NoticeRecaptcha">
    <i18n path="recaptcha" tag="div">
      <template #policy>
        <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">
          {{ $t('policy') }}
        </a>
      </template>
      <template #terms>
        <a href="https://policies.google.com/terms" rel="noreferrer" target="_blank">
          {{ $t('terms') }}
        </a>
      </template>
    </i18n>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.NoticeRecaptcha {
  font-size: $h8;
  margin: 1rem 0;
}
</style>

<i18n>
{
  "de": {
    "policy": "Datenschutz",
    "recaptcha": "Diese Seite ist durch reCAPTCHA geschützt und es gelten die {policy} und {terms} von Google.",
    "terms": "Nutzungsbedingungen"
  },
  "en": {
    "policy": "Privacy Policy",
    "recaptcha": "This site is protected by reCAPTCHA and the Google {policy} and {terms} apply.",
    "terms": "Terms of Service"
  },
  "it": {
    "policy": "",
    "recaptcha": "",
    "terms": ""
  },
  "lt": {
    "policy": "Privatumo politika",
    "recaptcha": "Ši svetainė yra apsaugota reCAPTCHA, taip pat taikoma „Google“ {policy} ir paslaugų {terms}.",
    "terms": "Naudojimosi taisyklės"
  },
  "pl": {
    "policy": "Polityka prywatności",
    "recaptcha": "Ta strona jest chroniona przez reCAPTCHA; obowiązuje {policy} i {terms} Google.",
    "terms": "Warunki korzystania z usług"
  },
  "es": {
    "policy": "",
    "recaptcha": "",
    "terms": ""
  }
}
</i18n>

<template>
  <div id="FormContact">
    <FormValidation v-if="!hasSent" @submit="send">
      <ValidationObserver ref="formObs" tag="div">
        <div v-if="isLandowner && locale === 'de'" class="german-landowner-form">
          <Row>
            <Column :lg="6">
              <Field
                autocomplete="full-name"
                :label="$t('name')"
                :maxLength="50"
                required
                :value.sync="contact.name" />
            </Column>
            <Column :lg="6">
              <Field
                autocomplete="phone"
                :label="$t('phone')"
                :maxLength="50"
                required
                :value.sync="contact.phone" />
            </Column>
          </Row>
          <Field
            class="textarea"
            :label="$t('messageDetailed')"
            :maxlength="1000"
            type="textarea"
            :value.sync="contact.message" />
        </div>
        <div v-else-if="isLandowner && locale === 'pl'" class="polish-landowner-form">
          <Row>
            <Column :lg="6">
              <Field
                autocomplete="full-name"
                :label="$t('name')"
                :maxLength="50"
                required
                :value.sync="contact.name" />
            </Column>
            <Column :lg="6">
              <Field
                autocomplete="phone"
                :label="$t('phone')"
                :maxLength="50"
                required
                :value.sync="contact.phone" />
            </Column>
          </Row>
          <Row>
            <Column :lg="6">
              <Field
                autocomplete="email"
                :label="$t('email')"
                :maxLength="130"
                rules="email"
                type="email"
                :value.sync="contact.email" />
            </Column>
            <Column :lg="6">
              <Field
                :label="$t('area')"
                :maxLength="100"
                required
                :value.sync="contact.area" />
            </Column>
          </Row>
          <Row>
            <Column :lg="6">
              <Field
                :label="$t('plot')"
                :maxLength="50"
                required
                :value.sync="contact.plot" />
            </Column>
            <Column :lg="6">
              <Field
                :label="$t('city')"
                :maxLength="100"
                required
                :value.sync="contact.city" />
            </Column>
          </Row>
          <Row>
            <Column :lg="3">
              <Field
                :label="$t('commune')"
                :maxLength="100"
                required
                :value.sync="contact.commune" />
            </Column>
            <Column :lg="3">
              <Field
                :label="$t('county')"
                :maxLength="100"
                required
                :value.sync="contact.county" />
            </Column>
            <Column :lg="6">
              <Field
                :label="$t('voivodeship')"
                :maxLength="50"
                required
                :value.sync="contact.voivodeship" />
            </Column>
          </Row>
          <Field
            :label="$t('howFound')"
            :options="howFoundOptions"
            type="select"
            :value.sync="contact.howFound" />
          <Field
            class="textarea"
            :label="$t('additionalQuestion')"
            :maxlength="1000"
            type="textarea"
            :value.sync="contact.message" />
        </div>
        <div v-else class="common-form">
          <Row>
            <Column :lg="6">
              <Field
                v-if="hasName"
                autocomplete="full-name"
                :label="$t('name')"
                :maxLength="100"
                required
                :value.sync="contact.name" />
            </Column>
            <Column
              :lg="hasName ? 6 : 12">
              <Field
                autocomplete="email"
                :label="$t('email')"
                :maxLength="130"
                required
                rules="email"
                type="email"
                :value.sync="contact.email" />
            </Column>
          </Row>
          <Field
            class="textarea"
            :label="$t('message')"
            :maxlength="1000"
            type="textarea"
            :value.sync="contact.message" />
        </div>
        <div class="checkboxes">
          <Row>
            <Column :md="8">
              <NoticeRecaptcha />
              <FieldConsent :value.sync="contact.hasAcceptedPolicy" />
              <FieldConsentMarketing :value.sync="contact.hasAcceptedMarketing" />
            </Column>
            <Column class="submit-column" :md="4">
              <Btn :isLoading="isSending" type="submit">
                {{ $t('send') }}
              </Btn>
            </Column>
          </Row>
        </div>
      </ValidationObserver>
    </FormValidation>
    <Notice v-if="hasSent && message" :class="message.theme">
      {{ message.text }}
    </Notice>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import Message from 'views/mixins/Message'
import NoticeRecaptcha from './NoticeRecaptcha'
import { isMock } from '../../config'
import { softThrow } from '../../bugsnag'

export default {
  mixins: [Message],
  components: { NoticeRecaptcha },
  props: {
    hasName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contact: this.$store.getters.contactFactory({
        formType: 'footer',
      }),
      isSending: false,
      hasSent: false,
      howFoundOptions: [
        'Facebook',
        'Google',
        'Ulotki',
        'Plakaty',
        'Inne',
      ],
    }
  },
  computed: {
    isLandowner() {
      return this.isLandownerSolution(this)
    },
    gtagEventName() {
      return this.isLandowner ? 'FormSubmissionLarge' : 'FormSubmissionSmall'
    },
    ...getters('isLandownerSolution', 'referrers'),
  },
  methods: {
    async send() {
      try {
        this.isSending = true
        if (!isMock) {
          const recaptcha = await this.$recaptchaToken('contact')
          await this.CREATE_CONTACT({
            ...this.contact,
            type: this.isLandowner ? 'landowner' : 'regular',
            // ...this.isLandowner ? { type: 'landowner' } : {},
            metaReferrers: this.referrers,
            recaptcha,
          })
        }

        this.hasSent = true

        this.$gtag.event(this.gtagEventName)

        if (this.isLandowner) {
          this.$analytics.fbq.event('Lead')
          this.success(this.$t('successLandowner'))
        } else {
          this.success(this.$t('success'))
        }
      } catch (error) {
        error.body = this.contact
        softThrow(error)
        this.ERROR(error)
      } finally {
        this.isSending = false
      }
    },
    ...actions('CREATE_CONTACT'),
  },
}
</script>

<style lang="scss">
#FormContact {
  margin: 3rem 0 0;

  .Field-cont {
    margin-top: 3rem;
  }

  .german-landowner-form {
    textarea {
      .Row {
        padding-bottom: 15rem;
      }
    }
  }

  textarea {
    min-height: 80px;
  }

  .checkboxes {
    margin-top: 0.5rem;
    text-align: left;
    @include md {
      margin-top: 0;
    }
  }

  form {
    @include lg {
      margin-top: 3rem;
    }

    input,
    select,
    textarea {
      color: $white;
      text-align: left;
      text-align-last: left;
    }

    select option {
      color: $text-color;
    }

    .checkboxes {
      .Field-errors {
        margin-bottom: 1rem;
      }

      .submit-column {
        align-items: center;
        display: flex;
        text-align: center;
        @include md {
          text-align: right;
        }
      }
    }

    .Btn {
      margin: auto;
      max-width: 20rem;
      min-width: 9rem;
      padding: 1.25rem 0;
      width: 100%;
      @include sm {
        width: auto;
      }
    }
  }

  .Notice {
    margin-top: 2rem;
  }
}
</style>

<i18n>
{
  "de": {
    "additionalQuestion": "",
    "area": "",
    "areaHelp": "",
    "city": "",
    "commune": "",
    "county": "",
    "email": "E-Mail",
    "howFound": "",
    "message": "Ihre Nachricht",
    "messageDetailed": "Ihre Nachricht (bitte fügen Sie Informationen über das Land hinzu, das Sie mieten möchten)",
    "name": "Ihr Name & Nachname",
    "phone": "Ihre Telefonnummer",
    "plot": "",
    "plotHelp": "",
    "send": "Senden",
    "success": "Wir danken Ihnen. Wir werden Sie in Kürze kontaktieren.",
    "successLandowner": "Wir danken Ihnen. Wir werden Sie in Kürze kontaktieren.",
    "voivodeship": ""
  },
  "en": {
    "additionalQuestion": "",
    "area": "",
    "areaHelp": "",
    "city": "",
    "commune": "",
    "county": "",
    "email": "Email",
    "howFound": "",
    "message": "Your message",
    "name": "Your name",
    "phone": "",
    "plot": "",
    "plotHelp": "",
    "send": "Send",
    "success": "Thank you. We will contact you shortly.",
    "successLandowner": "Thank you. We will contact you shortly.",
    "voivodeship": ""
  },
  "it": {
    "additionalQuestion": "",
    "area": "",
    "areaHelp": "",
    "city": "",
    "commune": "",
    "county": "",
    "email": "",
    "howFound": "",
    "message": "",
    "name": "",
    "phone": "Numero di Telefono",
    "plot": "Estensione (Ha)",
    "plotHelp": "",
    "send": "",
    "success": "",
    "successLandowner": "",
    "voivodeship": ""
  },
  "lt": {
    "additionalQuestion": "",
    "area": "",
    "areaHelp": "",
    "city": "",
    "commune": "",
    "county": "",
    "email": "El. paštas",
    "howFound": "",
    "message": "Jūsų žinutė",
    "name": "Jūsų vardas",
    "phone": "",
    "plot": "",
    "plotHelp": "",
    "send": "Siųsti",
    "success": "Dėkojame! Netrukus su jumis susisieksime. ",
    "successLandowner": "",
    "voivodeship": ""
  },
  "pl": {
    "additionalQuestion": "Dodatkowe pytania",
    "area": "Powierzchnia",
    "areaHelp": "Potrzebujemy gruntów o minimalnej powierzchni 1,6 ha, klasy IV-VI lub nieużytki, umożliwi nam to zbudowanie instalacji o mocy 1 MGW",
    "city": "Miejscowość/obręb",
    "commune": "Gmina",
    "county": "Powiat",
    "email": "Email",
    "howFound": "Skąd dowiedział się Pan/Pani o naszej ofercie?",
    "message": "Twoja wiadomość",
    "name": "Twoje imię i nazwisko",
    "phone": "Numer telefon",
    "plot": "Numer działki",
    "plotHelp": "Numer działki jest nam potrzebny, by sprawdzić, czy działka spełnia nasze wymagania",
    "send": "Wyślij",
    "success": "Dziękujemy, wkrótce się z Tobą skontaktujemy.",
    "successLandowner": "Dziękujemy, skontaktujemy się z Tobą w ciągu 10 dni roboczych.",
    "voivodeship": "Województwo"
  }
}
</i18n>

<template>
  <ValidationProvider
    #default="{ classes, errors }"
    :disabled="!rules && !isRequired"
    :name="name"
    :rules="rules"
    slim>
    <div class="Field-cont" :class="{ active: isActive }">
      <label v-if="label && !is('checkbox', 'radio')" :for="inputId">
        {{ label }}
        <span v-if="isRequired" class="required">
          *
        </span>
      </label>
      <div class="Field" :class="[...fieldClasses, classes]">
        <Icon v-if="_icon" :name="_icon" />
        <input
          v-if="isInput"
          v-bind="attrs"
          :id="inputId"
          :type="_type"
          :value="_value"
          @change="change"
          @focus="setFocus(true)"
          @focusout="setFocus(false)"
          @input="set">
        <div v-else-if="is('checkbox')" class="checkbox">
          <label :for="inputId">
            <input
              v-bind="attrs"
              :id="inputId"
              :checked="value ? 'checked' : ''"
              type="checkbox"
              :value="value"
              @change="set($event.target.checked)"
              @input="set($event.target.checked)">
            <template v-if="$slots.label">
              <slot name="label" />
            </template>
            <template v-else>
              {{ label }}
            </template>
          </label>
        </div>
        <textarea
          v-else-if="is('textarea')"
          v-bind="attrs"
          :id="inputId"
          :value="_value"
          @change="change"
          @focus="setFocus(true)"
          @focusout="setFocus(false)"
          @input="set($event)" />
        <select
          v-else-if="is('select')"
          v-bind="attrs"
          :id="inputId"
          :value="_value"
          @blur="setSelectIcon('angle-down')"
          @change="set($event)"
          @focus="setFocus(true)"
          @focusout="setFocus(false);setSelectIcon('angle-down')"
          @mousedown="setSelectIcon('angle-up')"
          @mouseup="setSelectIcon('angle-down')">
          <option v-if="attrs.placeholderSelect" disabled :value="null">
            {{ attrs.placeholderSelect }}
          </option>
          <option
            v-for="opt in selectOptions"
            :key="`${opt[0]}`"
            :selected="opt[0] === value"
            :value="opt[0]">
            {{ opt[1] }}
          </option>
        </select>
        <transition name="slide-fade">
          <div v-show="errors[0]" class="Field-errors">
            {{ errors[0] }}
          </div>
        </transition>
        <slot name="footer" />
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { isNumeric, isObject, randomString } from 'views/utils'

const filters = {
  numeric: {
    // out: value => isNumeric(value) ? Number(value) : null,
    out: value => {
      return isNumeric(value) ? Number(value) : ' ' // null
    },
  },
  select: {
    // TODO: generalize into an auto type casting function
    out: value => {
      if (value === null || value === '' || value === 'null') return null
      if (value === 'true') return true
      if (value === 'false') return false

      return isNaN(Number(value)) ? value : Number(value)
    },
  },
}

export default {
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    icon: String,
    label: String,
    name: String,
    options: Array,
    required: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: [String, Object],
    value: {
      type: null,
    },
  },
  data() {
    return {
      inputId: randomString(),
      isFocused: false,
      selectIcon: 'angle-down',
    }
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
        'aria-label': this.ariaLabel,
        disabled: this.disabled,
        name: this.name,
        required: this.isRequired,
      }
    },
    ariaLabel() {
      const label = this.label ||
        this.$attrs.placeholder ||
        this.$attrs.ariaLabel ||
        this.$attrs['aria-label']

      if (typeof label !== 'string') return

      return label.replace('*', '').trim()
    },
    _icon() {
      return this.icon || {
        money: 'eur',
        select: this.selectIcon,
      }[this.type]
    },
    _type() {
      return {
        money: 'number',
      }[this.type] || this.type
    },
    _value() {
      return this.filterIn
        ? this.filterIn(this.value)
        : this.value
    },
    fieldClasses() {
      const conditions = [
        [this.disabled, 'disabled'],
        [this.isRequired, 'required'],
        [this.isFocused, 'focus'],
        [this.is('select'), 'trans'],
        [this._icon, 'with-icon'],
        [this.isInput, 'inp'],
        [this.isIconRight, 'icon-right'],
      ]

      return [
        `type-${this._type}`,
        ...conditions
          .filter(([condition]) => condition)
          .map(([_, classes]) => classes),
      ]
    },
    filterIn() {
      return {
        // check: filters.check.in,
        // time: filters.time.in,
      }[this.type]
    },
    filterOut() {
      return {
        text: value => value || null,
        // check: filters.check.out,
        money: filters.numeric.out,
        number: filters.numeric.out,
        select: filters.select.out,
      }[this.type]
    },
    isActive() {
      return this.isFocused || this.value
    },
    isIconRight() {
      return ['money'].includes(this.type)
    },
    isInput() {
      return ['email', 'hidden', 'number', 'password', 'percent', 'text'].includes(this._type)
    },
    isRequired() {
      return this.required !== false
    },
    selectOptions() {
      let options = []

      if (Array.isArray(this.options)) {
        if (this.options.length !== 0 && Array.isArray(this.options[0])) {
          // array of pairs [key, value]
          options = this.options
        } else {
          // array of values
          options = this.options.map(option => [option, option])
        }
      } else if (isObject(this.options)) {
        options = Object.keys(this.options).map(key => [key, this.options[key]])
      }

      // return array of pairs to ensure the order of elements
      return options
    },
  },
  methods: {
    change(event) {
      this.$emit('change', this.getEventValue(event))
    },
    getEventValue(event) {
      const value = isObject(event)
        ? event.target.value
        : event

      return this.filterOut
        ? this.filterOut(value)
        : value
    },
    is(...types) {
      return types.some(type => this.type === type)
    },
    set(event) {
      const value = this.getEventValue(event)

      this.$emit('update:value', value)
      this.$emit('input', value)
    },
    setSelectIcon(value) {
      this.selectIcon = value
    },
    setFocus(value) {
      this.isFocused = value
      this.$emit(`focus${value ? '' : 'out'}`)
    },
  },
}
</script>

<style lang="scss">
.Field-cont {
  font-size: $h5;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  position: relative;
  @include md {
    font-size: $h5;
    line-height: 1.5rem;
    margin-bottom: 4rem;
  }

  &.active {
    label {
      font-size: $h8;
      top: -2rem;
    }

    .Field.type-checkbox,
    .Field.type-radio {
      label {
        font-size: $h7;
      }
    }
  }
}

.Field-cont label,
label.field-label {
  @include trans;
  color: inherit;
  display: block;
  font-size: $h7;
  font-weight: $semibold;
  left: 0.5rem;
  line-height: 1rem;
  pointer-events: none;
  position: absolute;
  text-transform: uppercase;
  top: 0.25rem;
  @include md {
    line-height: inherit;
  }
}

.Field {
  input,
  select,
  textarea,
  input[type="number"],
  &.with-icon {
    // background: $white;
    border: none;
    border-bottom: 1px solid $border-color;
    border-radius: $radius;
    outline: none;
    width: 100%;

    &.focus {
      border-color: $info;
      // box-shadow: 0 0 2px $info;
    }
  }

  input,
  select,
  textarea,
  input[type="number"] {
    font-size: inherit;
    line-height: inherit;
  }

  textarea {
    line-height: 1.5rem;
    min-height: 200px;
  }

  input[type="number"] {
    padding-right: 0;
  }

  input,
  select,
  option,
  textarea {
    @include trans;
    padding: 0.25rem 0.5rem;

    &.success {
      border-color: $success;
    }

    &.info {
      border-color: $info;
    }

    &:active,
    &:focus {
      border-color: $info;
      // box-shadow: 0 0 2px $info;
    }
  }

  .checkbox {
    color: $text-color;
    cursor: pointer;
    display: inline-block;

    input {
      width: auto;
    }

    &.disabled {
      color: $text-color;
      cursor: not-allowed;
    }
  }

  .Field-errors {
    color: $danger;
    font-size: $h7;
  }

  &.type-select {
    label {
      line-height: 1rem;
      margin: 0.75rem 0 0;
      padding-left: 0.75rem;
    }
  }

  &.type-checkbox,
  &.type-radio {
    @include clearfix;

    input {
      margin-right: 0.5rem;
    }

    label {
      cursor: pointer;
      pointer-events: all;
      position: static;
    }
  }

  &.type-radio {
    margin-bottom: 0;
  }

  &.with-icon {
    padding-left: 3rem;
    padding-right: 0;
    position: relative;

    input,
    select {
      border: none;

      &:active,
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }

    > .Icon {
      border-right: 1px solid $border-color;
      font-size: $h4;
      font-weight: $regular;
      left: 0;
      line-height: inherit;
      padding: 0.33rem 1rem 0.25rem;
      position: absolute;
      top: 0;
    }

    // modifiers
    &.trans {
      > .Icon {
        background: transparent;
        border: none;
      }
    }

    &.icon-right {
      padding-left: 0;
      padding-right: 2.75rem;

      > .Icon {
        border-right: 0;
        left: auto;
        right: 0;
      }
    }

    &.type-select {
      padding-left: 0;
      padding-right: 0;

      > .Icon {
        left: auto;
        padding: 0.375rem 0.5rem;
        pointer-events: none;
        position: absolute;
        right: 0;
      }

      select {
        padding-right: 2rem;
      }
    }
  }

  // state
  &.disabled {
    input {
      background: $light;
    }
  }

  &.valid {
    input,
    &.with-icon {
      border-color: $success;
    }
  }

  &.invalid {
    margin-bottom: 0;

    input,
    &.with-icon {
      border-color: $danger;
    }
  }
}

label span.required {
  color: $danger;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=checkbox] {
  padding: 10px;
  -ms-transform: scale(1.25) translateX(2px); /* IE */
  -moz-transform: scale(1.25) translateX(2px); /* FF */
  -webkit-transform: scale(1.25) translateX(2px); /* Safari and Chrome */
  -o-transform: scale(1.25) translateX(2px); /* Opera */
  transform: scale(1.25) translateX(2px);
  @include md {
    -ms-transform: scale(1.5) translateX(2px); /* IE */
    -moz-transform: scale(1.5) translateX(2px); /* FF */
    -webkit-transform: scale(1.5) translateX(2px); /* Safari and Chrome */
    -o-transform: scale(1.5) translateX(2px); /* Opera */
    transform: scale(1.5) translateX(2px);
  }
}
</style>

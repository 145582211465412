<template>
  <router-link v-bind="$attrs" :title="title" :to="route">
    <slot />
  </router-link>
</template>

<script>
import Link from '@/views/mixins/Link'

export default {
  mixins: [Link],
}
</script>

<style lang="scss">
</style>

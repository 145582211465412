<template>
  <div class="PlantDataPoint">
    <Icon :name="icon" />
    <div class="body">
      <h5>{{ name }}</h5>
      <div class="value">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    name: String,
  },
}
</script>

<style lang="scss">
.PlantDataPoint {
  display: flex;
  margin-top: 1rem;
  width: 100%;

  > .body {
    flex-grow: 1;
    text-align: center;
    @include lg {
      transform: translateX(-1.5rem);
    }
    @include xxl {
      transform: none;
    }
  }

  .Icon {
    font-size: $h2;
  }

  h5 {
    font-size: $h7;
    line-height: 1.5rem;
  }

  .value {
    font-size: $h5;
  }
}
</style>

<template>
  <Btn class="BtnRound" @click="$emit('click')">
    <slot />
  </Btn>
</template>

<script>
export default {}
</script>

<style lang="scss">
$_button-background: #f4f4f4;
$_size: 2.5rem;

.Btn.BtnRound {
  background: $_button-background;
  border: none;
  border-radius: $radius-round;
  color: $black;
  height: $_size;
  line-height: $_size;
  min-width: $_size;
  padding: 0;
  width: $_size;

  .Icon {
    display: block;
    transform: translateX(1px);
  }

  &:hover,
  &:focus {
    background: darken($_button-background, 10%);
  }
}
</style>

export default {
  auto: '*',

  store: {
    getters: {
      getPortfolioItemImage: () => portfolioItem =>
        portfolioItem?.media?.formats?.small?.url || '../assets/focus.jpg',
    },
  },
}

<template>
  <picture>
    <source
      v-for="([format, formatSrc]) in sources"
      :key="`${formatSrc}_${format}`"
      :srcset="formatSrc"
      :type="`image/${getType(format) || format}`">
    <img v-bind="$attrs" :alt="alt" :src="srcAbs">
  </picture>
</template>

<script>
const formatTypes = {
  jpg: 'jpeg',
  svg: 'svg+xml',
}

function absoluteSourcePath(source) {
  if (source.startsWith('.')) {
    return require(`@/assets/${source.replace(/(\.\.?\/)+assets\//, '')}`)
  }

  return source
}

function getFormatAbsolutePath(relativePath, format) {
  return absoluteSourcePath(`${relativePath}.${format}`)
}

function formSource(relativePath, format) {
  return [
    format, getFormatAbsolutePath(relativePath, format),
  ]
}

export default {
  props: {
    alt: String,
    formats: Array,
    has2x: Boolean,
    src: String,
  },
  computed: {
    sources() {
      const pathWithoutFormat = [...this.src.split('.').slice(0, -1)].join('.')

      return [
        ...(this.formats || []),
        this.src.match(/\w*$/)[0],
      ].map((format) => {
        const sourceSet = formSource(pathWithoutFormat, format)

        if (!this.has2x) return sourceSet

        return [
          format,
          [
            `${sourceSet[1]} 1x`,
            `${formSource(`${pathWithoutFormat}-2x`, format)[1]} 2x`,
          ].join(','),
        ]
      })
    },
    srcAbs() {
      return absoluteSourcePath(this.src)
    },
  },
  methods: {
    getType(type) {
      return formatTypes[type]
    },
  },
}
</script>

<template>
  <transition appear name="fade">
    <ModalCont>
      <slot />
      <div v-if="modal.show" class="modal">
        <h3 v-if="modal.name" class="name">
          {{ modal.name }}
        </h3>
        <div class="text" :class="{ 'with-icon': modal.icon }">
          <Icon v-if="modal.icon" :name="modal.icon" />
          {{ modal.text }}
          <div v-if="modal.typeConfirm" class="type-confirm">
            <Field
              :label="`If you want to continue, please type in '${modal.typeConfirm}' below:`"
              :placeholder="modal.typeConfirm"
              type="text"
              :value.sync="typeConfirm" />
          </div>
        </div>
        <Row class="actions-row">
          <Column
            v-for="action in modal.actions"
            :key="action.label"
            :xs="12 / modal.actions.length">
            <Btn
              :class="action.theme"
              :disabled="isActionDisabled(action)"
              @click="process(action.action)">
              {{ action.label }}
            </Btn>
          </Column>
        </Row>
      </div>
    </ModalCont>
  </transition>
</template>

<script>
import { actions, getters } from '../../utils'

export default {
  data() {
    return {
      typeConfirm: null,
    }
  },
  computed: getters('modal'),
  methods: {
    process(actionName) {
      if (typeof actionName === 'function') {
        actionName(this)
      } else if (typeof this[actionName] !== 'undefined') {
        this[actionName]()
      }
    },
    close() {
      this.CLOSE_MODAL()
    },
    confirm() {
      this.modal.confirm()
      this.close()
    },
    isActionDisabled(action) {
      return action.action === 'confirm' &&
        (this.modal.typeConfirm && this.modal.typeConfirm !== this.typeConfirm)
    },
    ...actions('CLOSE_MODAL'),
  },
}
</script>

<style lang="scss">
.modal {
  @include trans;
  background: $white;
  border-radius: $radius-lg;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.3);
  min-width: 320px;
  pointer-events: auto;
  // max-width: 480px;
  width: 100%;
  z-index: 11;
  @include lg {
    max-width: 990px;
  }

  > .name {
    padding: 1.5rem 0;
    text-align: center;
  }

  > .text {
    font-size: $h4;
    font-weight: $medium;
    padding: 2rem 2rem 1rem;
    position: relative;

    > .Icon {
      font-size: $h1;
      left: 2rem;
      position: absolute;
      top: 1rem;
    }

    > .type-confirm {
      margin-top: 1rem;
      text-align: left;

      .field-cont {
        margin: 0;
      }
    }

    &.with-icon {
      padding-left: 6rem;
    }
  }

  > .actions-row {
    padding: 1.5rem;
  }
}
</style>

const {
  BASE_URL,
  NODE_ENV,
  VUE_APP_API_BASE,
  VUE_APP_IS_MOCK,
  VUE_APP_ROOT_API,
  VUE_APP_WEB_URL,
  VUE_APP_I18N_LOCALE,
  VUE_APP_BUGSNAG_KEY,
  VUE_APP_GOOGLE_MAPS_KEY,
} = process.env

const productionWebOrigin = 'suninvestmentgroup.com'
const clientOrigin = window.location.origin

export const basePath = BASE_URL || '/'

const clientUrl = `${clientOrigin}${basePath}`

export const webUrl = VUE_APP_WEB_URL ?? clientUrl

export const webOrigin = webUrl.replace(basePath, '').replace(basePath.slice(0, -1), '')

export const isDeployedProduction = clientOrigin.endsWith(productionWebOrigin)

export const isProduction = isDeployedProduction || NODE_ENV === 'production'

export const isMock = !isDeployedProduction && (VUE_APP_IS_MOCK ?? true).toString() === 'true'

export const defaultLocale = VUE_APP_I18N_LOCALE || 'en'

export const apiBase = VUE_APP_API_BASE ?? '/api'

export const apiOrigin = VUE_APP_ROOT_API || clientOrigin

export const apiUrl = isMock
  ? `${apiOrigin}/mock`
  : `${apiOrigin}${apiBase}`

export const bugsnagApiKey = VUE_APP_BUGSNAG_KEY

export const facebookId = '538030450429921'

// Google Analytics
export const analyticsId = 'UA-61929425-1'

export const googleMapsKey = VUE_APP_GOOGLE_MAPS_KEY

export const recaptchaKey = null
// '6LfbSswUAAAAAA5K34kcaP95dkFARMB_5P9WJ4Bt'
// '6LfqSswUAAAAAKBzIoKdb5srMG2BrVXKN__eCJHR'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { defaultLocale } from './config'
import { isObject } from './views/utils'

VueI18n.prototype.getChoiceIndex = function(choice, choicesLength) {
  // if (this.locale !== 'en') return undefined
  const lastIndex = choicesLength - 1

  const integer = parseInt(choice, 10)

  if (!integer) return 0

  const remainder = integer % 10
  if (!remainder) return lastIndex

  const isTeen = integer > 10 && integer < 20
  if (isTeen) return lastIndex

  const doesEndWithOne = remainder === 1
  if (doesEndWithOne) return 1

  return 2
}

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[\s\w,-]+\.json$/i)

  return Object
    .fromEntries(locales
      .keys()
      .flatMap((key) => {
        const matched = key.match(/([\w-]+)\./i)
        if (matched && matched.length > 1) {
          const locale = matched[1]
          return [[locale, locales(key)]]
        }
      }))
}

function castObjectToArray(object) {
  if (!isObject(object)) return object

  if (typeof object[0] === 'undefined') {
    return Object
      .fromEntries(Object
        .entries(object)
        .map(([key, value]) => [key, castObjectToArray(value)]))
  }

  return Object
    .values(object)
    .map(value => castObjectToArray(value))
}

Vue.prototype.$ta = function(key) {
  return castObjectToArray(this.$t(key))
}

const defaultDateTimeFormats = {
  short: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
}

const dateTimeFormats = {
  en: defaultDateTimeFormats,
  pl: defaultDateTimeFormats,
  lt: defaultDateTimeFormats,
}

export default new VueI18n({
  dateTimeFormats,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  silentFallbackWarn: true,
  messages: loadLocaleMessages(),
  warnHtmlInMessage: 'off',
})

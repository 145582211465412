<template>
  <form @submit.prevent="submit">
    <ValidationObserver ref="obs" slim>
      <slot />
    </ValidationObserver>
  </form>
</template>

<script>
export default {
  methods: {
    reset() {
      return this.$refs.obs.reset()
    },
    async submit() {
      if (!await this.$refs.obs.validate()) return

      this.$emit('submit')
    },
  },
}
</script>

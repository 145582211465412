import * as components from './views/components/globals'
import * as filters from './views/utils/filters'
import { ValidationObserver, ValidationProvider, configure, setInteractionMode } from 'vee-validate'
import { analyticsId, facebookId, isDeployedProduction, isProduction, recaptchaKey } from './config'
import App from './App'
import Router from 'vue-router'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueCookies from 'vue-cookies'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueGtag from 'vue-gtag'
import VueHead from 'vue-head'
import VueLocalStorage from 'vue-localstorage'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { _ } from '@feathersjs/commons'
import { attachBugsnag } from './bugsnag'
import index18n from './i18n'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.config.performance = !isProduction

Vue.use(VueHead)

// VeeValidate
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
configure({
  defaultMessage: (_, values) => index18n.t(`rules.${values._rule_}`, values),
})
setInteractionMode('eager')

// global filters
_.each(filters, (filter, name) => Vue.filter(name, filter))

// global components
_.each(components, (component, tag) => Vue.component(tag, component))

Vue.use(VueGtag, {
  config: {
    id: analyticsId,
  },
  disableScriptLoad: !isDeployedProduction,
  enabled: isDeployedProduction,
}, router)

Vue.use(Router)
Vue.use(VueClipboard)
Vue.use(VueLocalStorage)
Vue.use(VueFacebookPixel)
Vue.use(VueCookies)

new Vue({
  i18n: index18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

if (isDeployedProduction) {
  // define fbq for Facebook event register before their scripts are loaded
  /* eslint-disable */
  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
  /* eslint-enable */

  Vue.analytics.fbq.init(facebookId)
  Vue.analytics.fbq.event('PageView')
}

if (recaptchaKey) {
  Vue.use(VueReCaptcha, {
    siteKey: recaptchaKey,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
  Vue.prototype.$recaptchaToken = async function(actionName) {
    await this.$recaptchaLoaded()
    return this.$recaptcha(actionName)
  }
} else {
  Vue.prototype.$recaptchaToken = async function() {
    return 'none'
  }
}

attachBugsnag()

import { bugsnagApiKey, isDeployedProduction } from './config'
import BugsnagVue from '@bugsnag/plugin-vue'
import Vue from 'vue'
import bugsnag from '@bugsnag/js'

export function attachBugsnag() {
  if (!bugsnagApiKey) return

  bugsnag.start({
    apiKey: bugsnagApiKey,
    appType: 'client',
    onError(event) {
      if (!isDeployedProduction) return false

      const { originalError } = event

      event.addMetadata('details', {
        url: originalError.config?.url,
        method: originalError.config?.method,
        body: originalError?.body,
      })
    },
    collectUserIp: false,
    plugins: [new BugsnagVue()],
  })

  bugsnag
    .getPlugin('vue')
    .installVueErrorHandler(Vue)
}
export function softThrow(error) {
  if (!isDeployedProduction) throw error

  error.ignore && error.ignore()

  if (bugsnagApiKey) bugsnag.notify(error)
}

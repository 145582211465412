<template>
  <div>
    <TopBar v-if="isTranslated" />
    <RouterView />
    <FooterContact v-if="!pagesWithoutFooter.includes($route.name) && isTranslated" />
    <Footer v-if="isTranslated" />
  </div>
</template>

<script>
import Footer from 'views/components/Footer'
import FooterContact from 'views/components/FooterContact'
import TopBar from 'views/components/TopBar'
import { getters } from 'views/utils'

export default {
  components: { Footer, FooterContact, TopBar },
  data() {
    return {
      pagesWithoutFooter: ['Contact', 'Career', 'LandingFinance', 'LandingEnerfip'],
    }
  },
  computed: {
    isTranslated() {
      // TODO: ES is not translated, find better way to show topbar/footer
      return ['lt', 'en', 'pl', 'de', 'es'].includes(this.locale)
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
</style>

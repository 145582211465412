<script>
import { getters } from '../utils'

export default {
  props: {
    id: Number,
    params: Object,
    title: String,
    to: [String, Object],
  },
  computed: {
    routeParams() {
      const linkLocale = this.isStandardLocale(this.locale)
        ? this.locale
        : this.defaultLocale

      const params = {
        ...(linkLocale !== this.defaultLocale ? { locale: linkLocale } : {}),
        ...(this.params || {}),
      }

      if (this.id) params.id = this.id

      return params
    },
    route() {
      if (typeof this.to === 'string') {
        return {
          name: this.to,
          params: this.routeParams,
        }
      }

      return {
        ...this.to,
        params: {
          ...this.routeParams,
          ...(this.to.params || {}),
        },
      }
    },
    ...getters('defaultLocale', 'isStandardLocale'),
  },
  methods: {
    go() {
      if (this.to) {
        if (typeof this.to === 'string' && this.to.startsWith('http')) {
          location.href = escape(this.to)
        } else {
          this.$router.push(this.route)
        }
      }
    },
  },
}
</script>

<template>
  <transition appear name="fade">
    <div class="ModalCont">
      <div class="overlay" @click="close" @keypress.esc="close" />
      <div class="limit">
        <div class="modal">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeTo: String,
  },
  methods: {
    close() {
      if (this.closeTo) {
        this.$router.push({ name: this.closeTo })
      }
    },
  },
}
</script>

<style lang="scss">
.ModalCont {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;

  > .overlay {
    background: rgba(darken($main, 55%), 0.7);
    height: 100%;
    pointer-events: all;
    position: fixed;
    width: 100%;
  }

  > .limit {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    max-height: 100%;
    max-width: 840px;
    right: 0;
    top: 0;
    width: 100%;
  }

  &.lg .modal {
    max-height: 95%;
    max-width: 95%;
  }
}
</style>

<template>
  <div
    class="MenuLink"
    :class="{ active: isActive }">
    <Route
      v-if="link.to"
      :key="link.label"
      :title="link.label"
      :to="link.to"
      @click.native="$emit('click')"
      @keypress.enter.native="$emit('click')">
      <Icon v-if="link.icon" :name="link.icon" />
      {{ link.label }}
    </Route>
    <span
      v-else
      :key="link.label"
      ref="dropdown"
      class="parent-link"
      :tabindex="index + 2"
      @click="isOpen = !isOpen"
      @keypress.enter="isOpen = !isOpen">
      {{ link.label }}
      <Icon v-if="link.children" :name="`angle-${isOpen ? 'up' : 'down'}`" />
    </span>
    <transition name="slide-fade">
      <div v-if="isOpen" class="children">
        <div v-for="child in link.children" :key="child.label" class="child-link">
          <a
            v-if="child.href"
            :href="child.href"
            target="_blank">
            {{ child.label }}
          </a>
          <Route v-else :title="child.label" :to="child.to" @click.native="closeChildren">
            {{ child.label }}
          </Route>
        </div>
        <div key="close" class="child-link close">
          <Icon name="cross" /> {{ $t('close') }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { actions, isObject } from 'views/utils'

export default {
  props: {
    index: Number,
    link: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    isActive() {
      if (this.link.hasIgnoredActive) return false
      if (this.isSameRoute(this.link)) return true
      return this.link.children && this.link.children.some(this.isSameRoute)
    },
  },
  methods: {
    checkClickLocation(event) {
      if (!this.$refs.dropdown || !this.isOpen) return

      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false
      }
    },
    closeChildren() {
      this.isOpen = false
      this.$emit('click')
    },
    isSameRoute(link) {
      if (typeof link.to === 'string') {
        return link.to === this.$route.name
      }

      if (isObject(link.to)) {
        return link.to.name === this.$route.name
      }

      return false
    },
    ...actions(),
  },
  async beforeMount() {
    if (this.link.children) {
      this.$offEvents = await this.ON({
        click: this.checkClickLocation,
      })
    }
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
.MenuLink {
  display: inline-block;
  font-size: $h6;
  font-weight: $semibold;
  padding: 0 1rem;
  position: relative;
  @include md {
    padding: 0 0.5rem;
  }
  @include xl {
    padding: 0 1.15rem;
  }
  @include xxl {
    padding: 0 2rem;
  }

  > a,
  span {
    border-bottom: 0;
    color: $white;
  }

  > .parent-link {
    cursor: pointer;

    > .Icon > i {
      vertical-align: bottom;
    }
  }

  > .children {
    background: $white;
    color: $link-color;
    left: -6rem;
    max-width: 100vw;
    padding: 0.5rem 1.5rem;
    position: absolute;
    top: 2.5rem;
    width: 20rem;
    z-index: 1;
    @include lg {
      padding: 2.25rem 2.25rem 1.5rem;
      width: 25rem;
    }

    > .child-link {
      font-weight: $regular;
      line-height: 3rem;
      @include lg {
        line-height: inherit;
        margin-bottom: 1rem;
      }

      &.close {
        border-top: 1px solid;
        cursor: pointer;
        @include lg {
          padding-top: 0.75rem;
        }

        .Icon {
          color: $main;
          vertical-align: middle;
        }
      }
    }
  }

  &.active {
    > a,
    > span {
      border-bottom: 2px solid $white;
    }
  }

  // &.last {
  //   border-right: 0;
  // }
}

#mobile-menu .MenuLink {
  border: 0;
  border-bottom: 1px solid $white;
  display: block;
  font-size: $h4;
  font-weight: $regular;
  line-height: 4rem;
  width: 100%;

  > .children {
    top: unset;
  }

  &.last {
    border: 0;
  }
}
</style>

<i18n>
{
  "de": {
    "close": "Schließen"
  },
  "en": {
    "close": "Close"
  },
  "it": {
    "close": ""
  },
  "lt": {
    "close": "Uždaryti"
  },
  "pl": {
    "close": "Zamknij"
  }
}
</i18n>

<template>
  <Field
    class="consentMarketing"
    name="marketing"
    type="checkbox"
    :value="value"
    @update:value="$emit('update:value', $event)">
    <span slot="label">
      {{ $t('text') }}
    </span>
  </Field>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
}
</script>

<style lang="scss">
.consentMarketing {
  label {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}
</style>

<i18n>
{
  "de": {
    "text": "Ich stimme zu, dass meine persönlichen informationen zu kommunikations- und marketingzwecken verwendet werden "
  },
  "en": {
    "text": "I agree to my personal information being used for used for communication and marketing purposes"
  },
  "it": {
    "text": "Autorizzo il trattamento dei miei dati personali per finalità di Marketing e Comunicazione"
  },
  "lt": {
    "text": "Sutinku, kad mano duomenys būtų naudojami marketingo ir komunikacijos tikslais"
  },
  "pl": {
    "text": "Wyrażam zgodę na wykorzystanie informacji podanych w niniejszym formularzu do celów komunikacyjnych i marketingowych"
  },
  "es": {
    "text": "Acepto que mis datos personales se utilicen con fines de comunicación y marketing"
  }
}
</i18n>

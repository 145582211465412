<template>
  <Field
    class="consentPolicy"
    name="policy"
    :rules="{ required: { allowFalse: false } }"
    type="checkbox"
    :value="value"
    @update:value="$emit('update:value', $event)">
    <span slot="label">
      {{ $t('read') }} <Route :params="localeOverride" to="PrivacyPolicy">
        {{ $t('policy') }}
      </Route>
    </span>
  </Field>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    value: Boolean,
  },
  computed: {
    localeOverride() {
      return this.locale === 'en' ? null : { locale: this.locale }
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.consentPolicy {
  label {
    font-size: 0.75rem;
  }
}
</style>

<i18n>
{
  "de": {
    "policy": "die Datenschutzerklärung",
    "read": "Ich habe gelesen die"
  },
  "en": {
    "policy": "the privacy policy",
    "read": "I have read"
  },
  "it": {
    "policy": "Privacy",
    "read": "Ho letto l’informativa sulla"
  },
  "lt": {
    "policy": "Privatumo politika",
    "read": "Sutinku su"
  },
  "pl": {
    "policy": "polityka prywatności",
    "read": "Przeczytałem/-am"
  },
  "es": {
    "policy": "la política de privacidad",
    "read": "He leído"
  }
}
</i18n>

const imageFallback = '../assets/news/solar-panels-3.jpg'

export default {
  auto: '*',

  store: {
    getters: {
      getPostImage: () => post => (post.image || {}).url || imageFallback,
      getPostImagePreview: () => post => (post.imagePreview || post.image || {}).url || imageFallback,
    },
  },
}

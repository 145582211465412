<!-- eslint-disable sonarjs/no-identical-functions -->
<template>
  <div id="TopBar">
    <Wrap class="xl">
      <MainLogo />
      <div id="menu" :aria-label="$t('label')" :class="{ show: showMenu }" role="navigation">
        <MenuLink
          v-for="(link, index) in menu"
          :key="index"
          :index="index"
          :link="link" />
      </div>
      <div id="mobile-menu-cont">
        <div
          ref="hamburger"
          id="hamburger"
          @click="showMenu = !showMenu"
          @keypress.enter="showMenu = !showMenu">
          <Icon :name="showMenu ? 'cross' : 'bars'" />
        </div>
        <transition name="slide-side">
          <div v-if="showMenu" ref="mobileMenu" id="mobile-menu" role="navigation">
            <div>
              <MenuLink
                v-for="(link, index) in menu"
                :key="link.label"
                :class="{ last: index === (menu.length - 1) }"
                :index="index"
                :link="link"
                @click="hideMenu" />
            </div>
          </div>
        </transition>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters, mergeLabels } from 'views/utils'
import MainLogo from './MainLogo'
import MenuLink from './MenuLink'

export default {
  components: { MainLogo, MenuLink },
  data() {
    return {
      showMenu: false,
      solutionsMenuByLocale: {
        en: [
          {
            label: 'For Businesses',
            href: 'https://eterniasolar.com/',
          },
          {
            label: 'For Landowners',
            to: {
              name: 'Solution',
              params: {
                slug: 'for-landowners',
              },
            },
          },
        ],
        pl: [
          {
            label: 'Dla właścicieli ziem',
            href: 'https://suninvestmentgroup.com/pl/landing-landowners#contacts',
          },
        ],
        lt: [
          {
            label: 'Verslui',
            href: 'https://eterniasolar.com/lt',
          },
          {
            label: 'Žemės savininkams',
            href: 'https://suninvestmentgroup.com/lt/landing-landowners#form',
          },
        ],
        de: [
          {
            label: 'Lösungen für den deutschen Markt',
            to: {
              name: 'Solution',
              params: {
                slug: 'deutschen-markt',
              },
            },
          },
        ],
      },
      staticMenu: [
        {
          children: [
            { to: 'AboutUs' },
            { to: { name: 'AboutUs', hash: '#reach' } },
            { to: { name: 'AboutUs', hash: '#focus' } },
            { to: { name: 'AboutUs', hash: '#business-model' } },
            { to: { name: 'AboutUs', hash: '#philosophy' } },
            { to: { name: 'AboutUs', hash: '#team' } },
            { to: { name: 'AboutUs', hash: '#group' } },
          ],
        },
        { to: 'Portfolio' },
        { to: 'NewsPress' },
        { to: 'Career' },
        {
          children: [
            { to: { name: 'Contact' } },
            { to: { name: 'Contact', hash: '#offices' } },
          ],
        },
      ],
    }
  },
  computed: {
    localeMenu() {
      return {
        label: 'En',
        hasIgnoredActive: true,
        children: this
          .locales
          .map(({ label, value }) => ({
            label,
            to: {
              name: this.isPathDynamic(this) ? 'Index' : this.$route.name,
              params: { locale: this.defaultLocale === value ? undefined : value },
            },
          })),
      }
    },
    menu() {
      const appendLabels = ({ children, ...item }, key) => ({
        ...item,
        children: mergeLabels(children, this.$ta(key)),
      })
      const [about, portfolio, news, career, contact] = this.staticMenu
      const { screenSizes } = this
      const isTablet = screenSizes.includes('md') && !screenSizes.includes('lg')
      const isWidescreen = screenSizes.includes('xl')
      const isMobile = screenSizes.length === 1
      const isDefaultLocale = this.defaultLocale === this.locale

      // const solutions = this.query('solution', { locale: this.locale })
      // const hideSolutionsIds = [
      //   1, 5, 8,
      // ]

      const menu = mergeLabels([
        {
          children: this.solutionsMenuByLocale[this.locale],
          // children: solutions
          //   .filter(solution => !hideSolutionsIds.includes(solution.id))
          //   .map((solution) => ({
          //     label: solution.name,
          //     to: {
          //       name: 'Solution',
          //       params: {
          //         slug: solution.slug,
          //       },
          //     },
          //   })),
        },
        appendLabels(about, 'menu.about'),
        portfolio,
        news,
        {
          ...career,
          doShow: isWidescreen || isMobile,
        },
        appendLabels(contact, 'menu.contact'),
        this.localeMenu,
      ], this.$ta('menu.top'))
        .map((item, index, menuArray) => ({
          ...item,
          doShow: isDefaultLocale || (!isTablet || (!index || index === menuArray.length - 1)),
        }))

      return menu.filter(item => typeof item.doShow === 'undefined' || item.doShow)
    },
    ...getters('defaultLocale', 'isPathDynamic', 'locales', 'screenSizes'),
  },
  methods: {
    checkClickLocation(event) {
      if (!this.$refs.mobileMenu || !this.showMenu) return

      if (!['hamburger', 'mobileMenu'].some(ref => this.$refs[ref].contains(event.target))) {
        this.hideMenu()
      }
    },
    hideMenu() {
      this.showMenu = false
    },
    ...actions(),
  },
  async beforeMount() {
    this.$offEvents = await this.ON({
      click: this.checkClickLocation,
    })
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
$_burger-width: 2.25rem;

#TopBar {
  position: absolute;
  width: 100%;
  z-index: 7;

  > .Wrap {
    border-bottom: 1px solid $white;
    padding: 1rem 0.5rem 0.125rem;
    @include xs {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
    @include md {
      border: 0;
    }
    @include xl {
      padding: 1rem 2rem 0.125rem;
    }
  }
}

#menu {
  color: $white;
  display: none;
  float: right;
  padding: 2.5rem 0;
  @include md {
    display: block;
    padding: 1rem 0;
  }
  @include xl {
    padding: 2rem 0;
  }
}

#mobile-menu-cont {
  float: right;
  @include md {
    display: none;
  }
}

#mobile-menu {
  align-items: center;
  background: $main;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 20%;
  position: fixed;
  top: 0;
  width: 80%;
  z-index: 10;

  .AuthMenu {
    display: block;
  }
}

#hamburger {
  background: $white;
  border-radius: $radius;
  color: $black-ter;
  cursor: pointer;
  float: right;
  font-size: 2rem;
  height: $_burger-width;
  line-height: $_burger-width;
  margin-top: 0.5rem;
  position: relative;
  text-align: center;
  width: $_burger-width;
  z-index: 11;
  @include maxSm {
    display: inline-block;
  }
}

// @animation
.slide-side-enter-active,
.slide-side-leave-active {
  @include trans;
  opacity: 1;
  transform: translateX(0);
}

.slide-side-enter,
.slide-side-leave-to {
  opacity: 0;
  transform: translateX(300px);
}
</style>

<i18n>
{
  "de": {
    "label": "Hauptnavigation",
    "menu": {
      "about": [
        "Über uns",
        "Kurzfakten",
        "Unser Fokus",
        "Geschäftsmodell",
        "Philosophie",
        "Management",
        "Gruppe"
      ],
      "contact": [
        "Kontaktieren Sie uns",
        "Büros"
      ],
      "top": [
        "Solarlösungen",
        "Über uns",
        "Portfolio",
        "News & Presse",
        "Karriere",
        "Kontakt",
        "De"
      ]
    }
  },
  "en": {
    "label": "Main navigation",
    "menu": {
      "about": [
        "About us",
        "Quick facts",
        "Our focus",
        "Bussiness model",
        "Philosophy",
        "Management",
        "Group"
      ],
      "contact": [
        "Contact Us",
        "Offices"
      ],
      "top": [
        "Solutions",
        "About us",
        "Portfolio",
        "News & Press",
        "Career",
        "Contact",
        "En"
      ]
    }
  },
  "it": {
    "label": "",
    "menu": {
      "about": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ],
      "contact": [
        "",
        ""
      ],
      "top": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ]
    }
  },
  "lt": {
    "label": "Meniu",
    "menu": {
      "about": [
        "Apie mus",
        "Faktai",
        "Mūsų tikslas",
        "Verslo modelis",
        "Vertybės",
        "Vadovybė",
        "Grupė"
      ],
      "contact": [
        "Susisiekite su mumis",
        "Biurai"
      ],
      "top": [
        "Sprendimai",
        "Apie mus",
        "Įgyvendinti projektai",
        "Naujienos ir spauda",
        "Karjera",
        "Kontaktai",
        "Lt"
      ]
    }
  },
  "pl": {
    "label": "Główna nawigacja",
    "menu": {
      "about": [
        "O nas",
        "SIG w liczbach",
        "Nasz cel",
        "Model biznesowy",
        "Filozofia",
        "Kadra zarządzająca",
        "Grupa"
      ],
      "contact": [
        "Skontaktuj się z nami",
        "Biura"
      ],
      "top": [
        "Rozwiązania",
        "O nas",
        "Zrealizowane projekty",
        "Komunikaty prasowe",
        "Kariera",
        "Kontakt",
        "Pl"
      ]
    }
  }
}
</i18n>

/* global ga, hj, window */

import Vue from 'vue'

const getUrlParams = () => {
  try {
    return new URLSearchParams(window.location.search).get('r')
  } catch (error) {
    return null
  }
}

export default {
  store: {
    state: {
      referrers: getUrlParams(),
      url: null,
      visited: [],
    },
    getters: {
      url: state => state.url,
      visited: state => state.visited,
      isPathDynamic: () => ({ $route: route }) => {
        if (!route) return null

        const dynamicParameters = ['id', 'slug']

        return route && Object
          .keys(route.params)
          .some(parameter => dynamicParameters.includes(parameter))
      },
      referrers: (state) => {
        const referrers = state.referrers || Vue.$cookies.get('referrers')
        return (typeof referrers === 'string') ? { r: referrers } : referrers
      },
    },
    actions: {
      SET_COOKIE(_, { key, value, expiration = '1y' }) {
        Vue.$cookies.set(key, value, expiration)
      },
      PAGEVIEW({ commit, getters }, { to, from }) {
        commit('SET_URL', to.path)
        commit('ADD_VISITED', to.path)

        // interface in the future
        if (!getters.isAdmin && from.name !== null) {
          const stats = {
            ga: path => ga('send', 'pageview', path),
            hj: path => hj('stateChange', path),
          }

          Object.keys(stats)
            .filter(key => window[key])
            .forEach((key) => {
              stats[key](to.path)
            })
        }
      },
      GO_BACK({ state }, { $router, fallback = 'Index' }) {
        if (state.visited.length > 1 && state.visited[0] !== state.url) {
          $router.go(-1)
        } else {
          $router.push({ name: fallback })
        }
      },
    },
    mutations: {
      ADD_VISITED(state, path) {
        state.visited.push(path)
      },
      SET_URL(state, path) {
        state.url = path
      },
    },
  },
}

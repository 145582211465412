<template>
  <div id="Footer">
    <Wrap>
      <div class="top">
        <div class="logo">
          <Pic loading="lazy" src="../../assets/logo.svg" />
          <div v-if="locale === 'lt'" class="certificate">
            <a class="certificate" :href="certificatePath" target="_blank">
              <Pic :alt="$t('certificateAlt')" :formats="['webp']" src="../../assets/sig_cert_logo.jpg" />
            </a>
          </div>
        </div>
        <div class="menu">
          <Row>
            <Column :md="4" :xl="6" :xs="0" />
            <Column v-for="menu in menus" :key="menu.label" :md="4" :xl="3">
              <div class="title">
                {{ menu.label }}
              </div>
              <ul v-for="({ isCareer, label, to }) in menu.links" :key="label">
                <li>
                  <Route :to="to">
                    {{ label }}
                    <Tag v-if="isCareer" class="info">
                      {{ careerCount }}
                    </Tag>
                  </Route>
                </li>
              </ul>
            </Column>
          </Row>
        </div>
      </div>
      <div class="bottom">
        <Row>
          <Column :lg="4">
            <div class="social">
              <SocialIcon
                v-for="({ href, icon, title }) in socials"
                :key="title"
                :href="href"
                :icon="icon"
                :title="title" />
            </div>
          </Column>
          <Column :lg="4">
            <div class="logos">
              <a
                v-for="logo in logos"
                :key="logo.label"
                :class="logo.classes"
                :href="logo.href"
                rel="noopener"
                target="_blank"
                :title="logo.label">
                <Pic
                  :alt="logo.label"
                  :formats="['webp']"
                  loading="lazy"
                  :src="logo.src" />
              </a>
            </div>
          </Column>
          <Column :lg="4">
            <div class="copyright">
              <div>
                © {{ new Date().getFullYear() }} Sun Investment Group
              </div>
              <Route to="PrivacyPolicy">
                {{ $t('policy') }}
              </Route>
            </div>
          </Column>
          <div v-if="locale === 'pl'" class="disclaimer">
            {{ $t('disclaimer') }}
          </div>
        </Row>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { getters, mergeLabels } from '../utils'
import SocialIcon from './SocialIcon'
import { webUrl } from '../../config'

export default {
  components: { SocialIcon },
  data() {
    return {
      certificatePath: `${webUrl}/assets/certificates/SIG.jpg`,
      doShowCertificate: false,
      logos: mergeLabels([
        {
          href: 'https://eterniasolar.com/',
          src: '../../assets/eternia-solar-logo-small.png',
        },
        {
          src: '../../assets/eternia-solar-services-logo-small.png',
        },
        {
          href: 'https://saulesbendruomene.lt/',
          src: '../../assets/saules-logo-small.png',
        },
      ], this.$ta('logos')),
      socials: [
        {
          href: 'https://www.facebook.com/sunInvestmentgroup/',
          icon: 'facebook',
          label: 'Facebook',
        },
        {
          href: 'https://www.linkedin.com/company/suninvestmentgroup/',
          icon: 'linkedin',
          label: 'LinkedIn',
        },
        {
          href: 'https://www.youtube.com/channel/UCcOLvuZbV0Cx_m4chjEmicQ',
          icon: 'youtube',
          label: 'Youtube',
        },
        {
          href: 'https://www.instagram.com/suninvestmentgroup/',
          icon: 'instagram',
          label: 'Instagram',
        },
      ],
    }
  },
  computed: {
    careerCount() {
      return this
        .query('career')
        .length
    },
    menus() {
      const hideSolutionsIds = [
        1, 5, 8,
      ]

      const solutions = this.query('solution', {
        locale: this.locale,
      }).filter(solution => !hideSolutionsIds.includes(solution.id))

      return [
        {
          label: this.$t('company.name'),
          links: mergeLabels([
            { to: 'AboutUs' },
            { to: 'NewsPress' },
            { to: 'Contact' },
            { to: 'Career', isCareer: true },
            { to: 'LandingFinance2024' },
          ], this.$ta('company.menu')),
        },
        {
          label: this.$t('solutions.name'),
          links: [
            ...solutions.map((solution) => ({
              label: solution.name,
              to: {
                name: 'Solution',
                params: {
                  slug: solution.slug,
                },
              },
            })),
            {
              label: this.$t('solutions.portfolio'),
              to: 'Portfolio',
            },
          ],
        },
      ]
    },
    ...getters('screenSizes'),
  },
}
</script>

<style lang="scss">
#Footer {
  background: $black;
  color: $white;
  font-weight: $semibold;
  padding-top: 8rem;

  .Wrap {
    padding: 0 2.5rem;

    .top {
      display: flex;
      flex-direction: column;
      margin-bottom: 6rem;
      @include md {
        flex-direction: row;
      }

      > .logo {
        text-align: center;
        @include md {
          text-align: left;
        }

        > .certificate {
          display: block;
          text-align: center;

          img {
            cursor: pointer;
            height: 150px;
            margin-top: 1.5rem;
            @include xl {
              margin-top: 4rem;
            }
          }
        }

        img {
          height: 3.5rem;
        }
      }

      > .menu {
        flex-grow: 4;

        .title {
          display: block;
          margin: 1.5rem 0;
          opacity: 0.4;
          @include md {
            margin-top: 0;
          }
        }

        .Tag {
          font-size: $h8;
          line-height: 0.5rem;
          min-width: 1.5rem;
          transform: translateY(0.25rem);
          vertical-align: top;
        }

        .Row {
          > div {
            text-align: center;
            // width: 100%;

            @include md {
              text-align: right;
            }
          }

          ul {
            list-style: none;

            > li {
              margin-bottom: 1.5rem;

              > a {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 4rem;

    > .Row {
      width: 100%;

      > div {
        padding: 0.5rem;
        text-align: center;

        &:first-child {
          @include lg {
            text-align: left;
          }
        }
      }
    }

    .social {
      flex-grow: 1;
    }

    .logos {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      text-align: center;
      @include lg {
        margin: 0;
        text-align: right;
      }

      > a {
        border: 0;
        display: inline-block;
        margin: 0 1rem;
        vertical-align: top;
        // width: 33.333%;
        // width: 9rem;
        width: 200px;

        img {
          margin-left: 0;
          width: 130px;
        }
      }
    }

    .copyright {
      color: $white;
      flex-grow: 2;
      font-weight: $regular;
      text-align: center;
      @include lg {
        text-align: right;
      }

      > div {
        margin-bottom: 0.5rem;
        opacity: 0.4;
      }

      > a {
        color: $white;
      }
    }

    .disclaimer {
      font-size: 0.5rem;
      font-weight: $regular;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "certificateAlt": "",
    "company": {
      "menu": [
        "Über uns",
        "News & Presse",
        "Kontakt",
        "Karriere",
        "Öffentliche Anleiheemission"
      ],
      "name": "Unternehmen"
    },
    "disclaimer": "",
    "logos": [
      "Eternia solar",
      "Eternia solar services",
      "Saulės Bendruomenė"
    ],
    "policy": "Datenschutz",
    "solutions": {
      "name": "Solarlösungen",
      "portfolio": "Portfolio"
    }
  },
  "en": {
    "certificateAlt": "SIG - Strongest in Lithuania",
    "company": {
      "menu": [
        "About us",
        "News & Press",
        "Contact",
        "Career",
        "Public Bond Issue"
      ],
      "name": "Company"
    },
    "disclaimer": "",
    "logos": [
      "Eternia solar",
      "Eternia solar services",
      "Saulės Bendruomenė"
    ],
    "policy": "Privacy Policy",
    "solutions": {
      "name": "Solar solutions",
      "portfolio": "Portfolio"
    }
  },
  "it": {
    "certificateAlt": "",
    "company": {
      "menu": [
        "",
        "",
        "",
        ""
      ],
      "name": ""
    },
    "disclaimer": "",
    "logos": [
      "",
      "",
      ""
    ],
    "policy": "",
    "solutions": {
      "name": "",
      "portfolio": ""
    }
  },
  "lt": {
    "certificateAlt": "SIG - Stipriausi Lietuvoje",
    "company": {
      "menu": [
        "Apie mus",
        "Naujienos ir spauda",
        "Kontaktai",
        "Karjera",
        "Viešas obligacijų platinimas"
      ],
      "name": "Įmonė"
    },
    "disclaimer": "",
    "logos": [
      "Eternia solar",
      "Eternia solar services",
      ""
    ],
    "policy": "Privatumo politika",
    "solutions": {
      "name": "Saulės energijos sprendimai",
      "portfolio": "Įgyvendinti projektai"
    }
  },
  "pl": {
    "certificateAlt": "SIG - Najsilniejszy na Litwie",
    "company": {
      "menu": [
        "O nas",
        "Komunikaty prasowe",
        "Kontakt",
        "Kariera",
        "Publiczna emisja obligacji"
      ],
      "name": "Firma"
    },
    "disclaimer": "SUN INVESTMENT DEVELOPMENT PL sp. z o.o. z siedzibą w Warszawie, pod adresem: Plac Marszałka Józefa Piłsudskiego 2, 00-073 Warszawa, Polska, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla Miasta Stołecznego Warszawy, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000646954, NIP: 5223075888, REGON: 365864670, kapitał zakładowy: 5.000,00 zł w całości wpłacony",
    "logos": [
      "Eternia solar",
      "Eternia solar services",
      ""
    ],
    "policy": "Polityka prywatności",
    "solutions": {
      "name": "Rozwiązania",
      "portfolio": "Zrealizowane projekty"
    }
  }
}
</i18n>

import { getterFinder, getterOptions } from '../utils'
import { apiBase } from '../../config'
import authentication from '@feathersjs/authentication-client'
import { checkRoute } from '@/router/authorization'
import client from '../client'
import router from '@/router'

const removeSymbols = string => string.replace(/[<=>]/g, '')

client.configure(authentication({
  path: `${apiBase}authentication`,
  storageKey: 'amjwt',
}))

export default {
  store: {
    state: {
      doSkip: false,
      isLoggingOut: false,
      signupEmail: null,
      userId: null,
    },

    getters: {
      authUser: ({ userId }, { query }) => userId
        ? query('user', userId)
        : null,
      authUserSettings: (_, { isAuth, authUser }) => isAuth
        ? authUser.settings
        : {},

      authHeaders: () => {
        const Authorization = window.localStorage.amjwt
        return Authorization ? { Authorization } : {}
      },

      doSkipAuth: state => state.doSkip,
      isAuth: (_, { authUser }) => !!authUser,
      signupEmail: state => state.signupEmail,

      roles: () => [
        { label: 'Admin', value: 'ADMIN' },
        { label: 'Manager', value: 'MANAGER' },
        { label: 'Support', value: 'SUPPORT' },
        { label: 'User', value: 'USER' },
      ],
      roleKeys: (_, getters) => getters.roles.map(role => role.value),
      roleOptions: getterOptions('roles'),
      getRole: getterFinder('roles'),

      parseRole: (_, { roleKeys }) => (role) => {
        if (role === '*') return [...roleKeys]

        const cleanRole = removeSymbols(role)

        if (cleanRole.length === role.length) return [role]

        if (!roleKeys.includes(cleanRole)) {
          throw new Error(`Invalid role label ${cleanRole}`)
        }

        const roleIndex = roleKeys.indexOf(cleanRole)

        if (role.includes('>=')) {
          return roleKeys.slice(0, roleIndex + 1)
        } else if (role.includes('>')) {
          return roleKeys.slice(0, roleIndex)
        } else if (role.includes('<=')) {
          return roleKeys.slice(roleIndex)
        } else if (role.includes('<')) {
          return roleKeys.slice(roleIndex + 1)
        }

        return [cleanRole]
      },

      hasRole: (_, { isAuth, authUser, parseRole }) => role =>
        isAuth && parseRole(role).includes(authUser.role),
    },

    actions: {
      CHECK_LOGIN: async ({ commit, dispatch, state }) => {
        if (state.doSkip || state.isLoggingOut) return Promise.resolve()

        commit('SET_AUTH_SKIP', true)

        try {
          const payload = await client.reAuthenticate()
          dispatch('SET_PROFILE', payload.user)
        } catch (error) {
          if (error.name === 'NotAuthenticated') {
            return
          } else if (error.name === 'NotFound') {
            dispatch('LOGOUT')
            return
          }

          throw error
        }
      },

      SIGNUP: ({ dispatch }, user) => dispatch('CREATE_USER', user),

      LOGIN: async ({ dispatch }, { email, password }) =>
        dispatch('SET_PROFILE', (await client.authenticate({
          strategy: 'local',
          email,
          password,
        })).user),

      LOGOUT: async ({ commit, dispatch }) => {
        commit('SET_LOGGING_OUT', true)

        await client.logout()

        delete localStorage.amjwt

        commit('SET_AUTH_USER_ID', null)
        commit('SET_LOGGING_OUT', false)

        // conditional that ignores unit testing as it does not have a router
        if (router.app.$route) {
          checkRoute(router.app.$route, router.app.$route)
        }

        dispatch('CLEAR_STATE')
      },

      SET_AUTH_SKIP({ state, commit }, doSkip) {
        if (state.doSkip !== doSkip) {
          commit('SET_AUTH_SKIP', doSkip)
        }
      },

      SET_PROFILE({ commit }, user) {
        commit('ADD_USER', user)
        commit('SET_AUTH_USER_ID', user.id)
        return user
      },

      SET_SIGNUP_EMAIL({ commit }, signupEmail) {
        commit('SET_SIGNUP_EMAIL', signupEmail)
      },
    },

    mutations: {
      SET_AUTH_SKIP: (state, doSkip) => {
        state.doSkip = doSkip
      },

      SET_LOGGING_OUT: (state, isLoggingOut) => {
        state.isLoggingOut = isLoggingOut
      },

      SET_AUTH_USER_ID: (state, userId) => {
        state.userId = userId
      },

      SET_SIGNUP_EMAIL: (state, signupEmail) => {
        state.signupEmail = signupEmail
      },
    },
  },
}

<template>
  <Card class="CardPostSmall">
    <Route class="image" :params="{ slug: post.slug }" tag="div" to="Post">
      <Pic
        :alt="post.title"
        draggable="false"
        :src="getPostImagePreview(post)" />
    </Route>
    <div class="body">
      <div class="meta">
        <Tag class="sm">
          {{ post.tag }}
        </Tag>
        <div class="date">
          {{ $d(new Date(post.date), 'short') }}
        </div>
      </div>
      <Route class="title" :params="{ slug: post.slug }" to="Post">
        {{ post.title }}
      </Route>
      <BtnShare class="sm" :to="{ name: 'Post', params: { slug: post.slug } }" />
    </div>
  </Card>
</template>

<script>
import BtnShare from 'views/components/BtnShare'
import { getters } from '../../../utils'

export default {
  components: { BtnShare },
  props: {
    post: Object,
  },
  computed: getters('getPostImagePreview'),
}
</script>

<style lang="scss">
.CardPostSmall {
  > .image {
    cursor: pointer;
    height: 15rem;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  > .body {
    height: 20rem;
    padding: 2rem;
    position: relative;

    .BtnShare {
      bottom: 1rem;
      position: absolute;
      right: 1rem;
    }

    .more {
      bottom: 2rem;
      position: absolute;
      right: 2rem;
    }

    > .meta {
      color: $text-color;

      > .date {
        color: $grey;
        display: inline-block;
        margin-left: 1rem;
        vertical-align: middle;
      }
    }

    > a.title {
      @include ellipsis(4);
      color: $text-color;
      font-size: $h4;
      line-height: 2rem;
      margin: 2rem 0 1rem;
      min-height: 6rem;
      @include lg {
        min-height: 3rem;
      }
    }
  }
}
</style>

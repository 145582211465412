export default {
  auto: '*',

  store: {
    getters: {
      getSnippetValue: (_, { query }) => key => (query('snippet', { key }, true) || {}).value,
      snippetsValues: (_, { getSnippetValue }) => Object
        .fromEntries(['constructedMw', 'developingMw', 'developingGw', 'projects', 'projectsNogridBaltic', 'experts']
          .map(key => [key, getSnippetValue(key)])),

      injectSnippets: (_, { snippetsValues }) => text => Object
        .entries(snippetsValues)
        .reduce((currentText, [snippetKey, snippetValue]) => currentText
          .replace(`{${snippetKey}}`, snippetValue), text),

      injectCustomSnippets: (_) => (text, snippets) => Object
        .entries(snippets)
        .reduce((currentText, [snippetKey, snippetValue]) => currentText
          .replace(`{${snippetKey}}`, snippetValue), text),
    },
  },
}

<template>
  <div id="MapPlants">
    <div class="map-container">
      <div class="map">
        <MapPlantsLeaflet
          :countryId="activeCountryId"
          :officeId.sync="activeOfficeId"
          :plantId="activePlantId"
          @update:countryId="setCountryId"
          @update:plantId="setPlantId" />
      </div>
      <div class="sidebar" :class="{ active: isActive }">
        <div class="content">
          <div v-if="office" class="office">
            <div class="name">
              <h3>{{ office.name }} {{ $t('office') }}</h3>
              <h4 v-if="office.district">
                {{ office.district }}
              </h4>
            </div>
            <p>
              {{ office.description }}
            </p>
            <Row>
              <div class="contact">
                <Column :xs="6">
                  <Email :address="office.email" />
                </Column>
                <Column :xs="6">
                  <Phone :number="office.phone" />
                </Column>
              </div>
            </Row>
          </div>
          <div v-else-if="activePlantId" class="plant">
            <h3>{{ plant.name }}</h3>
            <Row v-if="plant.capacity && plant.capacity !== '0'">
              <Column :md="12" :sm="6" :xxl="6">
                <PlantDataPoint icon="bolt-recycle" :name="$t('capacity')">
                  {{ Number(plant.capacity) | kw }}
                </PlantDataPoint>
              </Column>
              <Column :md="12" :sm="6" :xxl="6">
                <PlantDataPoint icon="leaf-o" :name="$t('co2')">
                  {{ $tc('tonnes', tonnes) }}
                </PlantDataPoint>
              </Column>
            </Row>
            <div class="description">
              <p>
                {{ plant.description }}
              </p>
            </div>
            <MapLink
              v-if="country && country.plants.length === 1"
              :url="country.pipelineLink" />
          </div>
          <div v-else-if="country" class="country">
            <h3>{{ country.name }}</h3>
            <p class="description" v-html="countryDescription" />
            <MapLink :url="country.pipelineLink" />
            <MapLink :url="country.videoLink" />
          </div>
          <Row v-else class="no-gutters">
            <Column
              v-for="({ icon, label, text }, index) in stats"
              :key="icon"
              :md="12"
              :xs="6">
              <div class="type" :class="{ active: !index }">
                <div class="type-inner">
                  <Icon :name="icon" />
                  <strong>
                    {{ label }}
                  </strong>
                  <p>
                    {{ text }}
                  </p>
                </div>
              </div>
            </Column>
          </Row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import MapLink from './MapLink'
import PlantDataPoint from './PlantDataPoint'
import { scrollToHash } from '../../router/smoothScroll'

const MapPlantsLeaflet = () => import(/* webpackChunkName: 'leaflet' */ './MapPlantsLeaflet')

export default {
  components: { MapPlantsLeaflet, MapLink, PlantDataPoint },
  data() {
    return {
      activeCountryId: null,
      activeOfficeId: null,
      activePlantId: null,
      co2Ratio: 0.58,
      // eslint-disable-next-line xss/no-mixed-html
      countryDescriptionReplacables: [
        // eslint-disable-next-line xss/no-mixed-html
        ['[[Eternia Solar]]', '<a href="https://eterniasolar.com" target="_blank">Eternia Solar</a>'],
      ],
    }
  },
  computed: {
    country() {
      return this.query('country', {
        id: this.activeCountryId,
        locale: this.locale,
      }, true)
    },
    countryDescription() {
      // TODO: replace links with regex
      let description = this.country.description

      this.countryDescriptionReplacables.forEach(replacement => {
        description = description.replace(replacement[0], replacement[1])
      })

      return description
      // return this.country.description.replace('[[Eternia Solar]]', '<a href="https://eterniasolar.com" target="_blank">Eternia Solar</a>')
    },
    tonnes() {
      return Number((this.plant.capacity * this.co2Ratio).toFixed(2))
    },
    isActive() {
      return this.country || this.plant || this.office
    },
    office() {
      if (!this.activeOfficeId) return

      return this.query('office', this.activeOfficeId)
    },
    plants() {
      return this
        .query('country')
        .flatMap(country => country.plants)
    },
    plant() {
      return this.plants
        .find(plant => plant.id === this.activePlantId)
    },
    stats() {
      return [
        {
          icon: 'bolt',
          label: this.$t('constructedAmount', [this.getSnippetValue('constructedMw')]),
          text: this.$t('constructed'),
        },
        {
          icon: 'bolt-recycle',
          label: this.$t('developingAmount', [this.getSnippetValue('developingMw')]),
          text: this.$t('developing'),
        },
      ]
    },
    ...getters('getSnippetValue', 'isDesktop'),
  },
  methods: {
    scrollToMap() {
      if (this.isDesktop) return

      scrollToHash('#MapPlants')
    },
    setCountryId(countryId) {
      if (this.activeCountryId !== countryId) {
        this.activePlantId = null
      }

      this.activeCountryId = countryId
      this.scrollToMap()
    },
    setPlantId(plantId) {
      this.activePlantId = plantId

      // TODO: use countryId/country everywhere

      // this.$nextTick(() => {
      //   this.activeCountryId = plantId
      //     ? this.plant.country
      //     : null
      //   this.scrollToMap()
      // })
    },
    ...actions(),
  },
}
</script>

<style lang="scss">
#MapPlants {
  position: relative;

  // > .about {
  //   background: $black-bis;
  //   color: $white;
  //   font-size: $h5;
  //   line-height: $lh;
  //   padding: 2rem;
  //   text-align: center;

  //   em {
  //     color: $main;
  //     font-style: normal;
  //   }
  // }

  > .map-container {
    position: relative;

    > .map {
      height: 100%;
      min-height: 100vh;
      position: relative;
      z-index: 1;
      @include md {
        width: calc(100% - 30vw);
      }
    }

    > .sidebar {
      @include trans;
      background: $black-bis;
      bottom: 0;
      color: $white;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 2;
      @include md {
        height: 100%;
        width: 30vw;
      }

      > .content {
        height: 25rem;
        @include md {
          height: 100%;

          > .FlexRow {
            flex-direction: column;

            > .FlexColumn {
              min-height: 49.4%;
            }
          }
        }
        // height: 100%;

        > .Row {
          height: 100%;

          > .Column {
            min-height: 49.4%;
          }
        }

        h3 {
          margin-bottom: 1.5rem;
        }

        .office {
          font-size: $h5;
          @include md {
            font-size: $h6;
          }

          .name {
            margin-bottom: 1rem;
          }

          p {
            font-size: inherit;
            margin-bottom: 2rem;
          }

          .contact {
            text-align: center;

            a {
              border-bottom: 1px solid $white;
              color: $white;
              font-size: $h8;
            }
          }
        }

        .plant {
          text-align: center;
          width: 100%;

          .description {
            margin-top: 2rem;
          }
        }

        .country {
          .description {
            > a {
              color: $white;
              font-weight: $bold;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .type {
          align-items: center;
          display: flex;
          height: 100%;
          min-height: 15rem;
          text-align: center;

          > .type-inner {
            width: 100%;

            > .Icon {
              display: block;
              font-size: 2.25rem;
              margin-bottom: 1.5rem;
            }

            > strong {
              font-size: $h35;
              font-weight: $regular;
              @include lg {
                font-size: $h3;
              }
            }

            > p {
              font-size: $h7;
              @include md {
                font-size: $h6;
              }
            }
          }

          &.active {
            background: $info;
          }
        }
      }

      &.active {
        background: $info;

        .content {
          align-items: center;
          display: flex;
          min-height: 14rem;
          padding: 2rem;
          @include md {
            align-items: center;
            padding-top: 6rem;
          }
        }
      }
    }
  }

  // > .map {}
}
</style>

<i18n>
{
  "de": {
    "capacity": "Kapazität",
    "co2": "Eingespartes CO2/Jahr",
    "constructed": "Gebaut",
    "constructedAmount": "{0} MWp",
    "developing": "an Projekten befinden sich derzeit in der fortgeschrittenen Entwicklungsphase. ",
    "developingAmount": "über {0} MWp",
    "office": "büro",
    "tonnes": "{n} tonnen|{n} tonne|{n} tonnen|{n} tonnen"
  },
  "en": {
    "capacity": "Capacity",
    "co2": "CO2 saved/year",
    "constructed": "Constructed",
    "constructedAmount": "{0} MWp",
    "developing": "Under advanced stage of development",
    "developingAmount": "over {0} MWp",
    "office": "office",
    "tonnes": "{n} tonnes|{n} tonne|{n} tonnes|{n} tonnes"
  },
  "it": {
    "capacity": "",
    "co2": "",
    "constructed": "costruiti in Regno Unito, Polonia e Lituania",
    "constructedAmount": "{0} MWp",
    "developing": "Sviluppando",
    "developingAmount": "oltre {0} MWp",
    "office": "",
    "tonnes": ""
  },
  "lt": {
    "capacity": "Galia",
    "co2": "Kasmet sutaupoma CO2",
    "constructed": "Sumontuota",
    "constructedAmount": "{0} MWp",
    "developing": "Šiuo metu įgyvendinama",
    "developingAmount": "virš {0} MWp",
    "office": "biuras",
    "tonnes": "{n} tonų|{n} tona|{n} tonos|{n} tonų"
  },
  "pl": {
    "capacity": "Moc",
    "co2": "Oszczędność CO2/rok",
    "constructed": "Zbudowane",
    "constructedAmount": "{0} MWp",
    "developing": "W trakcie realizacji",
    "developingAmount": "ponad {0} MWp",
    "office": "biuro",
    "tonnes": "{n} tony|{n} tona |{n} tony|{n} tony"
  }
}
</i18n>

<template>
  <p v-if="url" class="MapLink">
    <a
      :alt="label"
      class="Btn sm bright"
      :href="url"
      rel="noreferrer"
      target="_blank">
      {{ label }}
    </a>
  </p>
</template>

<script>
export default {
  props: {
    url: String,
  },
  computed: {
    isVideo() {
      return ['vimeo', 'youtube'].some(video => this.url.includes(video))
    },
    label() {
      return this.isVideo
        ? this.$t('watch')
        : this.$t('map')
    },
  },
}
</script>

<style lang="scss">
.MapLink {
  margin-top: 2rem;
  text-align: center;
}
</style>

<i18n>
{
  "de": {
    "map": "Detaillierte Übersichtskarte",
    "watch": "Schau Video"
  },
  "en": {
    "map": "Detailed overview map",
    "watch": "Watch video"
  },
  "it": {
    "map": "",
    "watch": ""
  },
  "lt": {
    "map": "Detalus žemėlapis",
    "watch": "Žiūrėti video įrašą"
  },
  "pl": {
    "map": "Szczegółowa mapa",
    "watch": "Obejrzyj wideo"
  }
}
</i18n>

<template>
  <transition appear name="slide-fade">
    <div v-show="allowCookies === null" id="CookieBar">
      <Row>
        <Column :md="9">
          <div class="message">
            <i18n path="useCookies" tag="div">
              <template #policy>
                <!-- <router-link :to="`/${locale}/privacy-policy`" @click.native="fireGtagEvent('policy')">
                  {{ $t('policy') }}
                </router-link> -->
                <Route :params="localeOverride" to="PrivacyPolicy" @click.native="fireGtagEvent('policy')">
                  {{ $t('policy') }}
                </Route>
              </template>
            </i18n>
            <a
              :href="$t('link')"
              rel="noreferrer"
              target="_blank"
              @click="fireGtagEvent('cookies')"
              @keypress.enter="fireGtagEvent('cookies')">
              {{ $t('what') }}
            </a>
          </div>
        </Column>
        <Column :md="3">
          <Row class="actions">
            <Column :md="12" :xs="6">
              <Btn class="info sm" :icon="isDesktop ? 'check' : ''" @click="setCookie(true); fireGtagEvent('allow')">
                {{ $t('allow') }}
              </Btn>
            </Column>
            <Column :md="12" :xs="6">
              <Btn class="inv no-border sm" @click="setCookie(false); fireGtagEvent('disallow')">
                {{ $t('disallow') }}
              </Btn>
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  </transition>
</template>

<script>
import { actions, getters } from 'views/utils'

export default {
  data() {
    return {
      allowCookies: true,
    }
  },
  computed: {
    localeOverride() {
      return this.locale === 'en' ? null : { locale: this.locale }
    },
    ...getters('isDesktop', 'localStorage'),
  },
  methods: {
    fireGtagEvent(label) {
      this.$gtag.event('click', {
        event_category: 'cookieBar',
        event_label: label,
      })
    },
    setCookie(allowCookies) {
      this.allowCookies = allowCookies
      this.SET_LOCAL_STORAGE({ allowCookies })
    },
    ...actions('SET_LOCAL_STORAGE'),
  },
  created() {
    this.allowCookies = this.localStorage('allowCookies')
  },
}
</script>

<style lang="scss">
#CookieBar {
  background: $black;
  bottom: 0;
  box-shadow: $shadow-lg;
  color: $white;
  left: 0;
  padding: 0.5rem 0.5rem 1rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 7;
  @include md {
    padding: 1.5rem;
  }

  .message {
    padding: 1rem;
    @include md {
      padding: 1.5rem;
    }
  }

  .actions {
    align-content: center;
    height: 100%;
    text-align: center;

    .Btn {
      @include maxSm {
        padding: 0.5rem 1rem;
      }

      &.info {
        background: $info-dark;
        border-color: $info-dark;
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "allow": "Ich stimme",
    "disallow": "Nein danke",
    "link": "https://gdpr.eu/cookies/",
    "policy": "",
    "useCookies": "In order to ensure proper functioning of the website and better browsing experience, also for marketing purposes UAB „Sun Investment Group“ uses cookies. If you consent to the use of cookies, press „I agree“. You can withdraw your consent at any time by changing your browser settings and deleting recorded cookies. For more information please read our {policy}.",
    "what": "What are cookies"
  },
  "en": {
    "allow": "I agree",
    "disallow": "No, thanks",
    "link": "https://gdpr.eu/cookies/",
    "policy": "Privacy policy",
    "useCookies": "In order to ensure proper functioning of the website and better browsing experience, also for marketing purposes UAB „Sun Investment Group“ uses cookies. If you consent to the use of cookies, press „I agree“. You can withdraw your consent at any time by changing your browser settings and deleting recorded cookies. For more information please read our {policy}.",
    "what": "What are cookies"
  },
  "it": {
    "allow": "Acconsento",
    "disallow": "No, grazie",
    "link": "https://gdpr.eu/cookies/",
    "policy": "Politica sulla Riservatezza",
    "useCookies": "Al fine di garantire il corretto funzionamento del sito e una migliore esperienza di navigazione, anche per finalità di marketing UAB \"Sun Investment Group\" utilizza i cookie. Se acconsenti all'uso dei cookie, premi \"Acconsento\". È possibile revocare il consenso in qualsiasi momento modificando le impostazioni del browser e cancellando i cookie registrati. Per maggiori informazioni leggi la nostra {policy}.",
    "what": "Cosa sono i cookie"
  },
  "lt": {
    "allow": "Sutinku",
    "disallow": "Ačiū, ne",
    "link": "https://gdpr.eu/cookies/",
    "policy": "Privatumo politikoje",
    "useCookies": "Siekdama užtikrinti tinkamą interneto svetainės veikimą bei naršymo kokybę, taip pat rinkodaros tikslais UAB „Sun Investment Group“ naudoja slapukus. Jeigu sutinkate su slapukų įrašymu, spauskite “Sutinku”. Savo sutikimą bet kuriuo metu galite atšaukti pakeisdami naršyklės nustatymus ir ištrindami įrašytus slapukus. Daugiau informacijos {policy}.",
    "what": "Kas yra slapukai?"
  },
  "pl": {
    "allow": "Zaakceptuj pliki cookie",
    "disallow": "Nie, dziękuję",
    "link": "https://gdpr.eu/cookies/",
    "policy": "Polityka prywatności",
    "useCookies": "W celu zapewnienia prawidłowego funkcjonowania strony, lepszego przeglądania stron, jak również w celach marketingowych, SUN INVESTMENT DEVELOPMENT PL sp. z o.o. wykorzystuje pliki cookie. Jeśli wyrażasz zgodę na wykorzystanie plików cookie, naciśnij „Zgadzam się”. Możesz wycofać swoją zgodę w dowolnym momencie, zmieniając ustawienia przeglądarki i usuwając zarejestrowane pliki cookie. Aby uzyskać więcej informacji, przeczytaj naszą {policy}.",
    "what": "Czym są pliki cookie?"
  }
}
</i18n>

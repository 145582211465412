export default {
  auto: '*',

  store: {
    getters: {
      isLandownerSolution: () => ({ $route: route }) => {
        if (!route) return false

        const landowners = [
          'wlascicieli-ziem',
          'rozwiazania-dla-wlascicieli-ziem',
          'for-landowners',
          'landowners',
          'zemes-savininkams',
          'deutschen-markt',
        ]

        return (route.name === 'Solution' && landowners.includes(route.params.slug)) ||
          (route.name === 'FaqLandowners')
      },
      isBusinessSolution: () => ({ $route: route }) => {
        if (!route) return false

        const business = [
          'for-business',
          'rozwiazania-dla-firm',
        ]

        return route.name === 'Solution' && business.includes(route.params.slug)
      },
    },
  },
}

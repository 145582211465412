<template>
  <section id="FooterContact">
    <Wrap class="xl">
      <Splitter>
        <div slot="left">
          <h2>
            {{ $t('title') }}
          </h2>
          <p v-if="isNewsPress" class="contact-details">
            {{ $t('details') }}
            <a href="mailto:ag@suninvestmentgroup.com">
              ag@suninvestmentgroup.com
            </a>
          </p>
        </div>
        <div slot="right">
          <FormContact />
        </div>
      </Splitter>
    </Wrap>
  </section>
</template>

<script>
import FormContact from './FormContact'
import Splitter from './Splitter'
import { getters } from '../utils'

export default {
  components: { FormContact, Splitter },
  computed: {
    isNewsPress() {
      return this.$route.name === 'NewsPress'
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#FooterContact {
  background-color: $main;
  color: $white;
  padding: 1rem 1rem 3rem;
  @include md {
    background-image: url('../../assets/ellipses-full.png');
    background-position: top left;
    padding: 5rem;
  }

  .Splitter {
    .Column.left {
      align-items: center;
      display: flex;
      text-align: center;
      @include lg {
        text-align: initial;
      }
    }
  }

  h2 {
    font-size: $h3;
    margin: 2rem 0;
    @include md {
      font-size: $h2;
      margin: 0;
    }
  }

  .contact-details a {
    color: $white;
    text-decoration: underline;
  }

  #FormContact {
    form {
      margin: 0;
    }

    .Field-cont {
      margin-bottom: 1rem;
      @include md {
        margin-bottom: 1rem;
      }

      .Field-errors {
        color: $white;
      }

      .Field {
        input,
        textarea {
          color: $white;

          &:focus {
            border-color: $white;
          }
        }

        &.type-checkbox {
          label {
            color: $white;

            a {
              color: $white;
              text-decoration: underline;
            }
          }
        }
      }

      &.active {
        label {
          color: $white;
        }
      }

      &.textarea {
        margin-bottom: 0;
      }
    }

    .NoticeRecaptcha {
      a {
        color: $white;
        text-decoration: underline;
      }
    }

    button.Btn {
      background: $white;
      color: $info;

      > .Loader {
        color: $main;
      }

      &:hover,
      &:focus {
        background: $info-light;
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "details": "für Medienanfragen wenden Sie sich bitte an",
    "title": "Nehmen Sie Kontakt auf"
  },
  "en": {
    "details": "for media enquiries please contact ",
    "title": "Get in touch"
  },
  "it": {
    "details": "",
    "title": ""
  },
  "lt": {
    "details": "Žurnalistams",
    "title": "Susisiekite su mumis"
  },
  "pl": {
    "details": "w sprawie zapytań od mediów prosimy o kontakt ",
    "title": "Kontakt"
  }
}
</i18n>

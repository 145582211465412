import { render, staticRenderFns } from "./Block.vue?vue&type=template&id=f5fd1710&functional=true"
var script = {}
import style0 from "./Block.vue?vue&type=style&index=0&id=f5fd1710&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <section id="SectionMediaSpotlight">
    <Wrap>
      <h2>{{ $t('spotlight') }}</h2>
      <WrapScroll>
        <Column v-for="post in press" :key="post.id" class="item" :lg="4">
          <CardPress :press="post" />
        </Column>
        <template #button>
          <Route class="Btn sm inv" to="NewsPress">
            {{ $t('all') }}
          </Route>
        </template>
      </WrapScroll>
    </Wrap>
  </section>
</template>

<script>
import CardPress from 'views/components/CardPress'
import WrapScroll from 'views/components/WrapScroll'
import { getters } from 'views/utils'

export default {
  components: { CardPress, WrapScroll },
  computed: {
    press() {
      return this.query('press', {
        $limit: 10,
        $sort: { id: -1 },
      })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#SectionMediaSpotlight {
  h2 {
    margin-bottom: 4rem;
  }

  .CardPress {
    white-space: normal;
  }
}
</style>

<i18n>
{
  "de": {
    "all": "Alle Veröffentlichungen",
    "spotlight": "Im Rampenlicht der Medien"
  },
  "en": {
    "all": "All publications",
    "spotlight": "In the media spotlight"
  },
  "it": {
    "all": "",
    "spotlight": ""
  },
  "lt": {
    "all": "Visi straipsniai ",
    "spotlight": "Mes spaudoje"
  },
  "pl": {
    "all": "Wszystkie publikacje",
    "spotlight": "Media o nas"
  }
}
</i18n>

<template>
  <section id="LatestNews">
    <Wrap>
      <h2>{{ $t('title') }}</h2>
      <WrapScroll>
        <Column v-for="post in posts" :key="post.id" class="item" :lg="5">
          <CardPostSmall :post="post" />
        </Column>
        <template #button>
          <Route class="Btn sm inv" to="NewsPress">
            {{ $t('all') }}
          </Route>
        </template>
      </WrapScroll>
    </Wrap>
  </section>
</template>

<script>
import CardPostSmall from './CardPostSmall'
import WrapScroll from 'views/components/WrapScroll'
import { getters } from 'views/utils'

export default {
  components: { CardPostSmall, WrapScroll },
  data() {
    return {
      showEnglishLocale: [
        'de',
      ],
    }
  },
  computed: {
    posts() {
      const locale = this.showEnglishLocale.includes(this.locale) ? 'en' : this.locale

      return this.query('post', {
        locale,
        tag: 'SIG news',
        $limit: 10,
        $sort: { id: -1 },
      })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#LatestNews {
  h2 {
    margin-bottom: 2rem;
    @include md {
      margin-bottom: 4rem;
    }
  }

  .CardPostSmall {
    white-space: normal;
  }
}
</style>

<i18n>
{
  "de": {
    "all": "Alle Nachrichten",
    "title": "Neueste Nachrichten"
  },
  "en": {
    "all": "All news",
    "title": "Latest news"
  },
  "it": {
    "all": "",
    "title": ""
  },
  "lt": {
    "all": "Visos naujienos",
    "title": "Paskutinės naujienos"
  },
  "pl": {
    "all": "Wszystkie wiadomości",
    "title": "Najnowsze wiadomości"
  }
}
</i18n>

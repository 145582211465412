<template>
  <div id="Index">
    <Hero :formats="['webp']" src="../assets/landing/header.jpg">
      <h1 v-html="$t('title')" />
      <!-- <p>
        <Route to="Solution" :params="{ slug: investorsSolutionSlug }">
          <Icon name="angle-right" />
          {{ $t('invest') }}
        </Route>
      </p> -->
      <!-- <p>
        <Route :params="{ slug: businessSolutionSlug }" to="Solution">
          <Icon name="angle-right" />
          {{ $t('solution') }}
        </Route>
      </p> -->
      <HeroScrollDown hash="#about-us" slot="outside" />
    </Hero>
    <SectionSplit
      id="about-us"
      :headline="$t('about.headline')"
      src="../assets/landing/about-us.jpg">
      <h3>
        {{ $t('about.title') }}
      </h3>
      <p>
        {{ $t('about.content') }}
      </p>
      <Route class="Btn" to="AboutUs">
        {{ $t('about.more') }}
      </Route>
    </SectionSplit>
    <SectionOurReach />
    <div id="map-plants">
      <MapPlants />
    </div>
    <LatestNews />
    <SectionMediaSpotlight />
  </div>
</template>

<script>
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import LatestNews from './components/LatestNews'
import MapPlants from 'views/components/MapPlants'
import Meta from 'views/mixins/Meta'
import SectionMediaSpotlight from './components/SectionMediaSpotlight'
import SectionOurReach from 'views/components/SectionOurReach'
import SectionSplit from 'views/components/SectionSplit'
import { actions } from 'views/utils'

export default {
  mixins: [
    Meta({}, undefined, require('@/assets/social/homepage.jpg')),
  ],
  components: {
    Hero,
    HeroScrollDown,
    LatestNews,
    MapPlants,
    SectionMediaSpotlight,
    SectionOurReach,
    SectionSplit,
  },
  computed: {
    businessSolutionSlug() {
      return this.$t('solutionSlugs.business')
    },
    // investorsSolutionSlug() {
    //   return this.$t('solutionSlugs.investors')
    // },
  },
  methods: actions('FETCH_COUNTRY', 'FETCH_OFFICE', 'FETCH_PRESS', 'FETCH_POST'),
  created() {
    this.LOAD(Promise.all([
      this.FETCH_COUNTRY(),
      this.FETCH_OFFICE(),
      this.FETCH_PRESS(),
      this.FETCH_POST(),
    ]))
  },
}
</script>

<style lang="scss">
#Index {
  .Hero {
    background-image: linear-gradient(#18203e, #53607e 50%, #10151d, #040809);

    h1 {
      margin-bottom: 4rem;
    }

    p {
      font-size: $h4;
      margin-bottom: 2rem;

      a {
        color: $white;

        .Icon {
          display: inline-block;
          transform: translateY(2px);
        }
      }
    }
  }

  #about-us {
    background-color: $white;

    .Btn {
      margin-top: 1.5rem;
    }
  }

  #SectionMediaSpotlight {
    padding-top: 0;
  }
}
</style>

<i18n>
{
  "de": {
    "about": {
      "content": "Sun Investment Group (SIG) ist eine Entwicklungs- und Investmentmanagementgesellschaft, die sich darauf konzentriert, in der sich schnell entwickelnden Solarenergiebranche gleichbleibende Qualität zu liefern",
      "headline": "Über uns",
      "more": "Mehr lesen",
      "title": "Wir übernehmen die Führung in der solaren Wertschöpfungskette"
    },
    "invest": "Invest in solar energy",
    "meta": {
      "title": {
        "complement": "Homepage",
        "inner": "Sun Investment Group",
        "separator": "-"
      }
    },
    "solution": "Nutzen Sie Solarenergie, um ein Unternehmen zu betreiben",
    "solutionSlugs": {
      "business": "for-business",
      "investors": "for-investors"
    },
    "title": "Committed to energy transition<br>and a sustainable planet"
  },
  "en": {
    "about": {
      "content": "By developing top-quality renewable energy generating capacity, we focus on energy transition and creating value for investors and the planet.",
      "headline": "About us",
      "more": "Read more",
      "title": "Sun Investment Group (SIG) - an international solar energy project development group of companies"
    },
    "invest": "Invest in solar energy",
    "meta": {
      "title": {
        "complement": "Homepage",
        "inner": "Sun Investment Group",
        "separator": "-"
      }
    },
    "solution": "Use solar to power a business",
    "solutionSlugs": {
      "business": "for-business",
      "investors": "for-investors"
    },
    "title": "Committed to energy transition<br>and a sustainable planet"
  },
  "it": {
    "about": {
      "content": "",
      "headline": "",
      "more": "",
      "title": ""
    },
    "invest": "",
    "meta": {
      "title": {
        "complement": "",
        "inner": "",
        "separator": ""
      }
    },
    "solution": "",
    "solutionSlugs": {
      "business": "",
      "investors": ""
    },
    "title": ""
  },
  "lt": {
    "about": {
      "content": "Sun Investment Group (SIG) yra saulės energetikos projektų vystymo įmonių grupė, užtikrinanti stabilią kokybę greitai kintančiame sektoriuje.",
      "headline": "Apie mus",
      "more": "Skaityti daugiau",
      "title": "Saulės energijos lyderiai"
    },
    "invest": "Investuokite į saulės energiją",
    "meta": {
      "title": {
        "complement": "Pagrindinis puslapis",
        "inner": "Sun Investment Group",
        "separator": "-"
      }
    },
    "solution": "Saulės energija jūsų verslo plėtrai",
    "solutionSlugs": {
      "business": "verslui",
      "investors": "investuotojams"
    },
    "title": "Committed to energy transition<br>and a sustainable planet"
  },
  "pl": {
    "about": {
      "content": "Sun Investment Group (SIG) zajmuje się rozwojem i zarządzaniem inwestycjami w elektrownie słoneczne. Celem spółki jest tworzenie wysokiej jakości rozwiązań w dynamicznie rozwijającej się branży energetyki solarnej.",
      "headline": "O nas",
      "more": "Czytaj więcej",
      "title": "Zdobywamy pozycję lidera w branży energetyki słonecznej"
    },
    "invest": "Zainwestuj w energię słoneczną",
    "meta": {
      "title": {
        "complement": "Strona główna",
        "inner": "Sun Investment Group",
        "separator": "-"
      }
    },
    "solution": "Wykorzystaj energię słoneczną w rozwoju swojej firmy",
    "solutionSlugs": {
      "business": "rozwiazania-dla-firm",
      "investors": "rozwiazania-dla-inwestorow"
    },
    "title": "Committed to energy transition<br>and a sustainable planet"
  }
}
</i18n>

<template>
  <Card class="CardPress">
    <div class="image">
      <Pic
        :alt="press.title"
        draggable="false"
        loading="lazy"
        :src="press.image.url" />
    </div>
    <div class="body">
      <a class="title" :href="press.href" :title="press.title">
        {{ press.title }}
      </a>
      <div class="meta">
        <a :href="press.href" rel="noreferrer" target="_blank" :title="press.title">
          {{ $t('language') }}: {{ press.language }}
        </a>
      </div>
      <BtnShare class="sm" :to="press.href" />
    </div>
  </Card>
</template>

<script>
import BtnShare from '../components/BtnShare'

export default {
  components: { BtnShare },
  props: {
    press: Object,
  },
}
</script>

<style lang="scss">
.CardPress {
  > .image {
    height: 4.5rem;
    padding: 2rem 2rem 0;

    img {
      height: 100%;
    }
  }

  > .body {
    padding: 1rem 2rem 2rem;
    position: relative;

    > a.title {
      color: $text-color;
      display: block;
      font-size: $h6;
      min-height: 4.5rem;
    }

    > .meta {
      padding-top: 1rem;

      > a {
        color: $text-color;
        font-size: $h8;
        opacity: 0.5;
      }
    }

    .BtnShare {
      bottom: 1rem;
      position: absolute;
      right: 1rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "language": "Sprache"
  },
  "en": {
    "language": "Language"
  },
  "it": {
    "language": ""
  },
  "lt": {
    "language": "Kalba"
  },
  "pl": {
    "language": "Język"
  }
}
</i18n>

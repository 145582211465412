import { castArray } from '../../views/utils'
import routes from '../routes'

const appendAuth = ({ auth }) => (child) => {
  if (!auth) return child

  const leadingAuth = castArray(child.auth ? child.auth : auth)
  return { ...child, auth: leadingAuth }
}

const flatten = children =>
  children.reduce((authorizations, route) => {
    authorizations.push(route.auth
      ? Object.assign(route, { auth: castArray(route.auth) })
      : route)

    if (route.children) {
      authorizations.push(...flatten(route.children.map(appendAuth(route))))
    }

    return authorizations
  }, [])

export default flatten(routes)
  .filter(route => route.name)
  .reduce((authorizations, { name, auth }) => {
    authorizations[name] = auth || null
    return authorizations
  }, {})

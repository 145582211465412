<template>
  <div id="app">
    <AlertBar />
    <Modal v-if="modal.show" :modal="modal" />
    <CookieBar />
    <RouterView />
  </div>
</template>

<script>
import './validationRules'
import { actions, getters } from './views/utils'
import AlertBar from './views/components/AlertBar'
import CookieBar from './views/components/CookieBar'
import { defaultLocale } from './config'

export default {
  name: 'App',
  components: { AlertBar, CookieBar },
  data() {
    return {
      hasCheckedLocale: false,
      timerInterval: null,
    }
  },
  computed: getters(
    'implyInitialLocale',
    'isPathDynamic',
    'isStandardLocale',
    'modal',
    'referrers',
  ),
  methods: actions(
    'AUTO_SET_LOCALE',
    'CHECK_SCREEN_SIZE',
    'FETCH_CAREER',
    'FETCH_POST',
    'FETCH_SNIPPET',
    'FETCH_SOLUTION',
    'INIT_LOCAL_STORAGE',
    'LOAD_LOCALE',
    'SET_LOCALE',
    'SET_COOKIE',
  ),
  watch: {
    '$route.name': {
      immediate: true,
      async handler(routeName) {
        if (!routeName || this.hasCheckedLocale) return

        this.hasCheckedLocale = true

        const routeLocale = this.implyInitialLocale()

        if (this.$route.meta.doRedirectLocale === false) return

        const locale = await this.AUTO_SET_LOCALE({
          isHomepage: this.$route.name === 'Index',
          routeLocale,
        })

        if (locale !== routeLocale && !(locale === defaultLocale && !routeLocale)) {
          if (this.isPathDynamic(this)) return

          const routeName = this.$route.name || 'Index'
          this.$router.replace({ name: routeName, params: { locale } })
        }
      },
    },
    '$route.params.locale': {
      handler(locale) {
        const localeCode = locale || defaultLocale
        document.documentElement.setAttribute('lang', localeCode)

        this.SET_LOCALE(localeCode)
      },
    },
  },
  async created() {
    this.INIT_LOCAL_STORAGE(this)

    await this.$nextTick()

    this.LOAD(Promise.all([
      this.FETCH_CAREER(),
      this.FETCH_SNIPPET(),
      this.FETCH_SOLUTION(),
    ]))

    this.CHECK_SCREEN_SIZE()

    this.$offEvents = await this.ON({
      resize: this.CHECK_SCREEN_SIZE,
    })
  },
  mounted() {
    // dispatch pre-render event only when locale is loaded
    this.$nextTick(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    })

    if (this.referrers) {
      this.SET_COOKIE({ key: 'referrers', value: this.referrers.r })
    }
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
    clearInterval(this.timerInterval)
  },
}
</script>

<style lang="scss">
@import './views/style/global';
</style>

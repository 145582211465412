<template>
  <Splitter class="SectionSplit" :left="5" :right="7">
    <slot slot="left" name="left">
      <Pic
        :alt="alt"
        class="image"
        :formats="formats"
        :src="src" />
    </slot>
    <div class="body" slot="right">
      <Wrap>
        <Headline v-if="headline">
          {{ headline }}
        </Headline>
        <slot />
      </Wrap>
    </div>
  </Splitter>
</template>

<script>
import Splitter from './Splitter'

export default {
  components: { Splitter },
  props: {
    alt: String,
    formats: {
      type: Array,
      default: () => ['webp'],
    },
    src: String,
    headline: String,
  },
}
</script>

<style lang="scss">
.SectionSplit {
  flex-direction: column;
  min-height: 60vh;
  @include lg {
    flex-direction: row;
    min-height: 100vh;
  }

  .image {
    display: block;
    height: 50vh;
    @include lg {
      height: 100vh;
    }

    > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .body {
    .Wrap {
      padding: 1rem;
      @include lg {
        padding: 0 1rem;
      }

      > h3 {
        color: $black-true;
        font-size: $h35;
        margin-bottom: 2rem;
        @include lg {
          font-size: $h3;
        }
      }

      p {
        margin-bottom: 2rem;
        opacity: 0.7;
      }

      ul {
        margin-left: 1.25rem;
      }
    }
  }
}
</style>

import { apiUrl } from '../../config'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import rest from '@feathersjs/rest-client'

axiosRetry(axios, {
  retries: 2,
  retryDelay: axiosRetry.exponentialDelay,
})

export default function() {
  this.configure(rest(apiUrl).axios(axios))
}

<template functional>
  <Route
    :class="['MainLogo', data.class, data.staticClass]"
    :exact="true"
    title="Sun Investment Group"
    to="Index">
    <Pic alt="Sun Investment Group" src="../../assets/logo.svg" />
  </Route>
</template>

<script>
export default {}
</script>

<style lang="scss">
.MainLogo {
  border: 0;
  display: inline-block;
  padding: 0.5rem 0;
  @include md {
    padding: 1.25rem 0;
  }
  @include lg {
    padding-top: 1.5rem;
  }

  img {
    height: 32px;
    @include md {
      height: 36px;
    }
    @include md {
      height: 40px;
    }
    @include xl {
      height: 60px;
    }
  }

  &.db {
    img {
      height: auto;
      width: 100%;
    }
  }

  &.router-link-exact-active {
    cursor: default;
  }
}
</style>

<template functional>
  <a :class="[data.class, data.staticClass]" :href="`tel:${props.number}`" v-on="listeners">
    <template v-if="!children">
      {{ props.number }}
    </template>
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    number: [String, Number],
  },
}
</script>

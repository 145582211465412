import { factory } from '../utils'

export default {
  auto: '*',
  store: {
    getters: {
      contactFactory: factory(({ locale }) => ({
        area: null,
        city: null,
        commune: null,
        county: null,
        email: null,
        hasAcceptedPolicy: false,
        hasAcceptedMarketing: false,
        message: null,
        name: null,
        phone: null,
        plot: null,
        voivodeship: null,
        landType: null,
        type: 'regular',
        formType: null,
        locale,
        coordinates: null,
        howFound: null,
      })),
    },
  },
}

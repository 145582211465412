<template>
  <div class="StatNumber">
    <div class="number">
      <span v-if="prefix" class="affix" :class="{ small: prefix.length > 2 }">
        {{ prefix }}
      </span>{{ number }}<span v-if="suffix" class="affix">
        {{ suffix }}
      </span>
    </div>
    <div class="name">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    number: [Number, String],
    prefix: String,
    suffix: String,
  },
}
</script>

<style lang="scss">
.StatNumber {
  margin-bottom: 1.5rem;
  @include md {
    margin-bottom: 0;
  }

  &::after {
    border-bottom: 1px solid $info;
    width: 20%;
  }

  > .number {
    color: $info;
    font-size: 3rem;
    @include lg {
      font-size: 5rem;
    }

    > .affix {
      font-size: $h3;

      &.small {
        font-size: $h6;
      }
    }
  }

  > .name {
    font-size: $h5;
    opacity: 0.4;
  }
}
</style>

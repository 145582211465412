<template functional>
  <div :class="['Card', data.class, data.staticClass]">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.Card {
  @include trans;
  background: $white;
  border: 1px solid $border-color-light;
  border-radius: $radius;
  box-shadow: $shadow-md;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: $shadow-lg;
  }
}
</style>
